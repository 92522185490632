import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #161618;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`
