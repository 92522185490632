import { ICalendar } from 'datebook'
import moment from 'moment'
import { EXAM_DATE_KEYS, TO_BE_ANNOUNCED } from '../constants'
import {
  COHORT_TYPES,
  COMPLETED,
  ENROLLED,
  IN_PROGRESS,
  FINAL_WEEK_PERCENTAGE,
  INTENSIVE_COHORT_DURATION,
  DEFAULT_DEADLINES_IN_WEEKS,
  EXTENDED_COHORT_DURATION
} from '../constants/cohort'
import { COURSE_NAMES_MAPPING } from '../constants/course'
import {
  addDuration,
  changeTimezone,
  convertEasternTimeToLocalTime, dateToSecondsSinceEpoch,
  getDateString,
  getFormattedDate,
  getWeekNumber
} from './dateTimeUtils'
import { capitalizeFirstLetter } from './'
import {
  addExamNumbersToCohortSyllabus, addScheduleToSections,
  getExamDatesFromCohortExamDates,
  getSectionByType, getSectionTitle
} from './syllabus'
import { EXAM } from '../constants/chapterTypes'
import { getCourseDisplayName } from './course'

/**
 * @param {string} assignmentName Example: Philosophy 7/6/22 - The Meaning of Life
 * @description Extract assignment name from a string that has cohort name followed by assignment name
 * @returns {string} Example: The Meaning of Life
 */
export const getAssignmentName = (assignmentName = '') => {
  return assignmentName?.substring(assignmentName.lastIndexOf(' - ') + 3) || ''
}

export const getProgress = (currentWeek, totalDuration) => {
  if (currentWeek > totalDuration) return 100
  return Math.round((currentWeek / totalDuration) * 100)
}

export const getCohortProgress = cohort => {
  if (!cohort) return

  const { fields: { dateStart, endTime, finalExamEndTime, duration } = {} } = cohort
  const now = new Date()
  const cohortStartDate = convertEasternTimeToLocalTime(dateStart, 'T12:00:00')
  const cohortEndDate = new Date(endTime || finalExamEndTime)
  if (now < cohortStartDate) return { status: ENROLLED, progress: 0 }
  if (now > cohortEndDate) return { status: COMPLETED, progress: 100 }

  const weekNumber = getWeekNumber(dateStart)
  const progress = weekNumber === duration
    ? FINAL_WEEK_PERCENTAGE
    : getProgress(weekNumber, duration)
  return { status: `Week ${weekNumber}`, progress }
}

export const getCohortType = cohort => {
  if (!cohort) return ''

  if (isExtendedAuditCohort(cohort)) return COHORT_TYPES.extendedAudit
  if (isAuditCohort(cohort)) return COHORT_TYPES.audit
  if (isIntensiveCohort(cohort)) return COHORT_TYPES.intensive
  if (isExtendedCohort(cohort)) return COHORT_TYPES.extended
  return COHORT_TYPES.standard
}

export const isAuditCohort = cohort => {
  if (!cohort?.name) return false

  const AUDIT_REGEX = /Audit/gi
  return AUDIT_REGEX.test(cohort.name)
}

export const isVIPCohort = cohort => {
  if (!cohort?.name) return false
  return cohort.name.toLowerCase().trim().endsWith('- vip')
}

export const isExtendedAuditCohort = cohort => {
  return isAuditCohort(cohort) && isExtendedCohort(cohort)
}

export const isIntensiveCohort = cohort => {
  if (!cohort?.duration) return false

  return cohort.duration <= INTENSIVE_COHORT_DURATION
}

export const isExtendedCohort = cohort => {
  if (!cohort?.duration) return false

  return cohort.duration >= EXTENDED_COHORT_DURATION
}

export const getCohortStatus = cohort => {
  if (!cohort) return null

  const { fields: { dateStart, finalExamEndTime } = {} } = cohort

  const cohortStartDate = convertEasternTimeToLocalTime(dateStart, 'T12:00:00')
  const now = new Date()

  if (now < cohortStartDate) return ENROLLED
  if (now > new Date(finalExamEndTime)) return COMPLETED

  return IN_PROGRESS
}

export const addCohortStatusAndFormatCohorts = cohorts => {
  if (!cohorts?.length) return cohorts

  return cohorts.map(cohort => {
    const { course } = cohort.fields

    return ({
      ...cohort,
      fields: {
        ...cohort.fields,
        course: {
          ...course,
          name: COURSE_NAMES_MAPPING[course.id] || course.name
        },
        status: getCohortStatus(cohort)
      }
    })
  })
}

export const sortCohortsInDescendingOrder = cohorts => cohorts.sort(
  (a, b) => new Date(b.fields.dateStart) - new Date(a.fields.dateStart)
)

export const sortCohorts = (cohorts) => {
  if (!cohorts?.length) return cohorts

  const inProgressCohorts = []
  const enrolledCohorts = []
  const completedCohorts = []

  cohorts.forEach(cohort => {
    const { fields: { status } } = cohort
    if (status === ENROLLED) return enrolledCohorts.push(cohort)
    if (status === COMPLETED) return completedCohorts.push(cohort)
    inProgressCohorts.push(cohort)
  })

  return [
    ...sortCohortsInDescendingOrder(inProgressCohorts),
    ...sortCohortsInDescendingOrder(enrolledCohorts),
    ...sortCohortsInDescendingOrder(completedCohorts)
  ]
}

export const filterCohorts = (cohorts, filters) => {
  const { selectedCourse, selectedStatus } = filters || {}
  const { value: selectedCourseDisplayName } = selectedCourse || {}
  const { value: selectedCohortStatus } = selectedStatus || {}

  return cohorts?.filter(cohort => {
    const {
      fields: { status: cohortStatus, course } = {}
    } = cohort

    if (!selectedCourseDisplayName && !selectedCohortStatus) return true

    const isSelectedCourse = selectedCourseDisplayName === getCourseDisplayName(course)
    const isSelectedCohort = selectedCohortStatus === cohortStatus

    if (!selectedCohortStatus) return isSelectedCourse
    if (!selectedCourseDisplayName) return isSelectedCohort

    return isSelectedCourse && isSelectedCohort
  })
}

export const updatedFilterCohorts = (cohorts, filters) => {
  const { selectedCourses, selectedStatus, selectedSchools, selectedStartDates } = filters || {}

  const selectedCourseDisplayNames = selectedCourses?.map(course => course.value) || []
  const selectedCohortStatuses = selectedStatus?.map(status => status.value) || []
  const selectedRelationships = selectedSchools?.map(school => school.value) || []
  const selectedStartDatesValues = selectedStartDates?.map(date => date.value) || []

  return cohorts?.filter(cohort => {
    const {
      fields: { status: cohortStatus, course, relationships, relationshipsFromAttempts, dateStart } = {}
    } = cohort

    const isSelectedCourse = selectedCourseDisplayNames.length === 0 || selectedCourseDisplayNames.includes(getCourseDisplayName(course))
    const isSelectedCohort = selectedCohortStatuses.length === 0 || selectedCohortStatuses.includes(cohortStatus)

    // Combine both relationship fields
    const allRelationships = [...new Set([...(relationships || []), ...(relationshipsFromAttempts || [])])]

    // Modified school filtering logic
    const isSelectedSchool = selectedRelationships.length === 0 ||
      allRelationships.some(rel => selectedRelationships.includes(rel))

    const isSelectedStartDate = selectedStartDatesValues.length === 0 || selectedStartDatesValues.includes(getDateString(dateStart))

    return isSelectedCourse && isSelectedCohort && isSelectedSchool && isSelectedStartDate
  })
}

export const splitCohortsByStatus = (cohorts, filters) => {
  const { selectedCourses, selectedSchools } = filters || {}

  const selectedCourseDisplayNames = selectedCourses?.map(course => course.value) || []
  const selectedRelationships = selectedSchools?.map(school => school.value) || []

  const filteredCohorts = cohorts?.filter(cohort => {
    const {
      fields: { course, relationships, relationshipsFromAttempts } = {}
    } = cohort

    const isSelectedCourse = selectedCourseDisplayNames.includes(getCourseDisplayName(course))
    const isSelectedSchool = selectedRelationships.includes(relationships?.[0]) ||
      selectedRelationships.some(selectedRelationship => relationshipsFromAttempts?.includes(selectedRelationship))

    if (selectedCourseDisplayNames.length === 0) {
      return isSelectedSchool
    }

    return isSelectedCourse && isSelectedSchool
  })

  const completedCohorts = filteredCohorts?.filter(cohort => cohort.fields.status === COMPLETED)
  const activeCohorts = filteredCohorts?.filter(cohort => [ENROLLED, IN_PROGRESS].includes(cohort.fields.status))

  return {
    completedCohorts,
    activeCohorts
  }
}

export const getCoursesFromCohorts = cohorts => {
  if (!cohorts?.length) return []

  return cohorts.reduce((acc, cohort) => {
    const course = cohort?.fields?.course || {}
    const displayName = getCourseDisplayName(course)
    if (acc.some(course => course.value === displayName)) return acc

    acc.push({ label: displayName, value: displayName })
    return acc
  }, [])
}

export const getCohortDeadline = ({ type, dateStart, deadline, duration }) => {
  if (deadline) {
    const cohortDropDate = convertEasternTimeToLocalTime(deadline)
    const formattedDate = getFormattedDate(cohortDropDate)
    if (formattedDate) return formattedDate
  }

  const cohortType = getCohortType({ duration })
  const numberOfWeeksToAdd = DEFAULT_DEADLINES_IN_WEEKS[`${type}${cohortType}`]
  if (!numberOfWeeksToAdd) return TO_BE_ANNOUNCED

  const newDeadline = addDuration({
    startDate: dateStart,
    durationType: 'weeks',
    durationValue: numberOfWeeksToAdd,
    formatString: 'YYYY-MM-DD'
  })
  if (!newDeadline) return TO_BE_ANNOUNCED

  const cohortDropDate = convertEasternTimeToLocalTime(newDeadline)
  return getFormattedDate(cohortDropDate) || TO_BE_ANNOUNCED
}

export const getCohortStartSecondsSinceEpoch = (cohort) => {
  const { dateStart } = cohort || {}
  const date = new Date(dateStart + 'T00:00:00')
  if (date.toString() === 'Invalid Date') return

  const datePST = changeTimezone(date, 'America/Los_Angeles')

  return Math.floor(datePST.getTime() / 1000)
}

export const getCalendarEvents = (schedule, courseName) => {
  if (!schedule?.length) return null
  const capitalizedCourseName = capitalizeFirstLetter(courseName)
  const calendarSchedule = schedule
    .map(sch => {
      if (sch.title === 'Practice Exam') return null

      return {
        ...sch,
        title: `[${capitalizedCourseName}] ${sch.title}`
      }
    })
    .filter(Boolean)

  const startTimeStamp = calendarSchedule[1]?.startTimestamp
  const endTimeStamp = calendarSchedule[1]?.endTimestamp

  // Since start and end time are required for the calendar to work
  // we return null if we don't have them
  // so that we don't render the calendar
  if (!startTimeStamp || !endTimeStamp) return null

  const calendarConfig = {
    title: `${capitalizedCourseName} Semester Begins`,
    description: calendarSchedule[0]?.description,
    start: new Date(startTimeStamp),
    end: new Date(endTimeStamp)
  }
  const calendar = new ICalendar(calendarConfig)

  calendarSchedule.slice(1).forEach(sch => {
    const event = new ICalendar({
      title: sch.title,
      description: sch.materialCovered,
      start: new Date(sch.startTimestamp),
      end: sch.endDate ? new Date(sch?.endTimestamp) : null
    })

    calendar.addEvent(event)
  })

  return calendar
}

export const getCohortSectionName = (cohortName) => {
  const sectionName = cohortName?.toLowerCase().includes('ggu')
    ? cohortName.split('-')[2]?.trim()
    : null
  return sectionName
}

export const isGGUCohort = cohortName => {
  return !!cohortName?.toLowerCase().includes('ggu')
}

export const getCohortAssessments = (cohort, syllabusData) => {
  const {
    fields: {
      cohortMilestones = []
    } = {}
  } = cohort || {}

  const assignments = getMilestonesAssignments(cohortMilestones)
  const exams = getMilestonesExams(cohort, syllabusData)

  return [...assignments, ...exams] || []
}

export const getMilestonesAssignments = (cohortMilestones) => {
  if (!cohortMilestones?.length) return []

  return cohortMilestones.map(milestone => {
    const { name, lockTime, unlockTime } = milestone || {}
    const milestoneLabel = getAssignmentName(name)
    const milestoneText = (lockTime && unlockTime)
      ? `${getFormattedDate(new Date(unlockTime))} - ${getFormattedDate(new Date(lockTime))}`
      : TO_BE_ANNOUNCED
    const startTime = unlockTime ? new Date(unlockTime) : Date.now()
    const endDateTime = lockTime ? new Date(lockTime) : Date.now()

    return {
      milestoneLabel,
      milestoneText,
      startTime,
      endDateTime
    }
  })
}

export const getMilestonesExams = (cohort, syllabus) => {
  if (!cohort || !syllabus) return []
  syllabus = addExamNumbersToCohortSyllabus(syllabus)
  const { syllabusData } = syllabus || {}
  const scheduleSection = addScheduleToSections(syllabusData)
  const exams = getSectionByType(scheduleSection, EXAM)

  return exams.map(exam => {
    const { startDate, endDate } = getExamDatesFromCohortExamDates(cohort, exam)
    const milestoneLabel = getSectionTitle(exam.title)
    const milestoneText = (startDate && endDate)
      ? `${getFormattedDate(new Date(startDate))} - ${getFormattedDate(new Date(endDate))}`
      : TO_BE_ANNOUNCED
    const startTime = startDate ? new Date(startDate) : Date.now()
    const endDateTime = endDate ? new Date(endDate) : Date.now()

    return {
      milestoneLabel,
      milestoneText,
      startTime,
      endDateTime
    }
  })
}

export const getActiveCohorts = (cohorts, courseId) => {
  if (!cohorts?.length) return []

  const activeCohorts = cohorts.filter(cohort => {
    const {
      fields: {
        dateStart,
        finalExamEndTime,
        finalDeadlineExt,
        course
      } = {}
    } = cohort
    const now = moment(new Date())
    const isActive = now.isBetween(dateStart, finalDeadlineExt || finalExamEndTime)

    return isActive && !isAuditCohort(cohort) &&
      !isVIPCohort(cohort) && courseId === course?.id
  }).map(cohort => {
    const { fields: { dateStart, duration } = {} } = cohort || {}
    const startDate = moment(dateStart).format('MM/DD/YYYY')
    const cohortLabel = `${startDate} - ${duration} weeks`

    return {
      ...cohort,
      label: cohortLabel,
      value: cohort.id
    }
  })

  return activeCohorts
}

export const getActiveExams = (exams, cohort) => {
  const noOfExams = exams?.length
  if (!noOfExams) return []

  const noOfExamTypes = EXAM_DATE_KEYS.length
  const cohortFields = cohort?.fields || {}

  const activeExams = exams
    .map((exam, index) => {
      const accessIndex = index === noOfExams - 1 ? noOfExamTypes - 1 : index
      const { start, end, extension } = EXAM_DATE_KEYS[accessIndex]

      return {
        label: exam.title,
        startDate: cohortFields?.[start],
        endDate: cohortFields?.[extension] || cohortFields?.[end],
        originalEndDate: cohortFields?.[end],
        hasExtension: !!cohortFields?.[extension],
        value: exam.title,
        id: exam.chapter_uuid,
        accessIndex
      }
    })
    .filter(exam =>
      moment(exam.startDate).isValid() &&
      moment(exam.endDate).isValid() &&
      moment(new Date()).isBetween(exam.startDate, exam.endDate)
    )

  return activeExams
}

export const getStartDatesFromCohortsWithStatus = (cohorts) => {
  const startDatesMap = new Map()

  cohorts.forEach(({ fields: { dateStart, status } }) => {
    if (!startDatesMap.has(dateStart)) {
      startDatesMap.set(dateStart, status)
    }
  })

  return Array.from(startDatesMap, ([dateStart, status]) => ({
    dateStart: getDateString(dateStart),
    status
  }))
}

// This function is used to display different states on the dates dropdown
export const splitStartDatesByStatus = ({ startDates, selectedStatus, activeCohorts, completedCohorts, dateFilter }) => {
  const ADJUST_COHORT_STATUS_MESSAGE = 'Adjust the Cohort Status Filter to View These Dates'
  const NO_RESULTS_FOUND_MESSAGE = 'No results found'

  const completedStartDates = startDates.filter(date => date.status === COMPLETED)
  const activeStartDates = startDates.filter(date => [ENROLLED, IN_PROGRESS].includes(date.status))
  const activeCohortsCount = activeStartDates?.length
  const completedCohortsCount = completedCohorts?.length

  // filteredUniqueCompletedDates and filteredUniqueActiveDates are used to
  // keep track on what data is filtered on the date dropdown
  const filteredUniqueCompletedDates = Array.from(new Set(
    completedCohorts?.map(dateStart => (getDateString(dateStart)))
      .filter(dateStart => dateStart.includes(dateFilter))))

  const filteredUniqueActiveDates = Array.from(new Set(
    activeCohorts?.map(dateStart => (getDateString(dateStart)))
      .filter(dateStart => dateStart.includes(dateFilter))))

  const completedStartDatesNotFound = completedStartDates.length > 0 &&
    filteredUniqueCompletedDates.length === 0

  const activeStartDatesNotFound = activeStartDates.length > 0 &&
    filteredUniqueActiveDates.length === 0

  const completedLabel = `COMPLETED COHORTS (${completedCohortsCount})`

  const options = [
    {
      label: `ACTIVE COHORTS (${activeCohortsCount})`,
      isTitleLabel: true,
      isFixed: true,
      options: selectedStatus && selectedStatus.some(status => [IN_PROGRESS, ENROLLED].includes(status.value))
        ? activeStartDates.map(date => ({ label: date.dateStart, value: date.dateStart }))
        : []
    },
    {
      label: completedLabel,
      isTitleLabel: true,
      isFixed: true,
      options: selectedStatus && selectedStatus.some(status => status.value === COMPLETED)
        ? completedStartDates.map(date => ({ label: date.dateStart, value: date.dateStart }))
        : []
    }
  ]

  completedStartDatesNotFound && options[1].options.push({
    label: NO_RESULTS_FOUND_MESSAGE,
    value: 'none',
    isDisabled: true,
    isFixed: true
  })

  activeStartDatesNotFound && options[0].options.push({
    label: NO_RESULTS_FOUND_MESSAGE,
    value: 'none',
    isDisabled: true,
    isFixed: true
  })

  if (selectedStatus && !selectedStatus.some(status => [IN_PROGRESS, ENROLLED].includes(status.value))) {
    options[0].options.push({
      label: ADJUST_COHORT_STATUS_MESSAGE,
      value: 'none',
      isDisabled: true,
      isFixed: true
    })
  }
  if (selectedStatus && !selectedStatus.some(status => status.value === COMPLETED)) {
    options[1].options.push({
      label: ADJUST_COHORT_STATUS_MESSAGE,
      value: 'none',
      isDisabled: true,
      isFixed: true
    })
  }
  return options
}

export const hasCohortEndFourYearsAgoOrMore = (cohortEndTime) => {
  if (!cohortEndTime) return undefined

  const currentDate = new Date()
  const fourYearsAgoDate = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 4)
  )

  const fourYearsAgoEpochTime =
    dateToSecondsSinceEpoch(fourYearsAgoDate)

  return cohortEndTime <= fourYearsAgoEpochTime
}
