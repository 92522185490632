import styled from 'styled-components'
import mediaQueries from '../../mediaQueries'

export const Content = styled.div`
  flex: 1;
  background: var(--canvas-primary);
  color: var(--text-primary);
  overflow: auto;
  height: calc(100vh - 64px);
  padding-bottom: 126px;
  padding-left: 58px;
  padding-right: 58px;
  padding-top: 64px;

  ${mediaQueries.small`
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
  `}
`
