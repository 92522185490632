import React, { Fragment, memo, useCallback } from 'react'
import {
  Nav,
  NavLink
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth0 } from '../../Auth0Provider/Auth0Provider'
import DoorClosedSvg from '../../../assets/icons/door-closed.svg'
import OutlierPartnersLogo from '../../../assets/icons/outlier-partners-logo.svg'
import ToolsSvg from '../../../assets/icons/tools.svg'
import HambarsSvg from '../../../assets/icons/Hamburger.svg'
import DropDownMenuComponent
  from '../../DropDownMenuComponent/DropDownMenuComponent'
import { GlobalStyle, Container, StyledHorizontalRuler, StyledNavBar } from './Header.styled'
import JumpToTool from '../JumpToTool/JumpToTool'
import ServerSwitch from '../../ServerSwitch/ServerSwitch'
import { isOutlierEngineer } from '../../../config'

const Header = () => {
  const { user, logout } = useAuth0()

  const logoutWithRedirect = useCallback(() => {
    logout({
      returnTo: window.location.origin
    })
  }, [logout])

  const showServerSwitch = isOutlierEngineer(user?.email) ||
    localStorage.getItem('showServerSwitch') === 'true'

  return (
    <>
      <GlobalStyle />
      <Container>
        <StyledNavBar light expand='md'>
          <NavLink exact='true' tag={Link} to='/' className='admin-logo'>
            <img src={OutlierPartnersLogo} alt='Outlier admin Logo' />
          </NavLink>
          <Nav className={`ml-auto d-flex align-items-center ${showServerSwitch ? 'flex-row' : ''}`} navbar>
            {showServerSwitch && (
              <ServerSwitch />
            )}
            {user.active ? <JumpToTool /> : null}
            <DropDownMenuComponent
              iconSrc={HambarsSvg}
              iconWidth='16'
              iconHeight='13.33'
            >
              {
                useCallback(() => {
                  return (
                    <>
                      <p className='mb-0 dropdown-header'>
                        {user && user.email}
                      </p>
                      {user.active
                        ? (
                          <>
                            <NavLink tag={Link} to='/' className='dropdown-item'>
                              <img src={ToolsSvg} alt='tools-icon' className='mr-2' />
                              Tools
                            </NavLink>
                            <StyledHorizontalRuler />
                          </>
                          )
                        : null}
                      <button
                        className='dropdown-item'
                        onClick={logoutWithRedirect}
                      >
                        <img
                          src={DoorClosedSvg}
                          alt='logout-icon'
                          className='mr-2'
                        />
                        Log out
                      </button>
                    </>
                  )
                }, [logoutWithRedirect, user])
              }
            </DropDownMenuComponent>
          </Nav>
        </StyledNavBar>
      </Container>
    </>
  )
}

export default memo(Header)
