import { Fragment } from 'react'
import { COURSE_ACCESS_TYPES, FREE_COURSES } from '../constants/course'
import { WRITING_ASSIGNMENT, REVIEW, ORIENTATION } from '../constants/chapterTypes'
import config from '../config'
import { FIRST_EDITION_SUBDOMAIN } from '../constants'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { getNameWithoutVersion } from '.'
import { uniqBy } from 'lodash'

export const filterPartnerCourses = (allCourses, catalogCourses) => {
  const _allCourses = allCourses || []
  const _catalogCourses = catalogCourses || []

  const catalogCourseIds = _catalogCourses.map(course => course.uuid.uuid)
  const freeCourseIds = Object.values(FREE_COURSES)

  return _allCourses.filter(course => {
    const isPartnerCourse = catalogCourseIds.includes(course.id) &&
        !freeCourseIds.includes(course.id)

    return isPartnerCourse
  })
}

export const filterCurrentPartnerCourses = (allCourses, relationshipName) => {
  const _allCourses = allCourses || []

  return _allCourses.filter(course => {
    const isCurrentPartnerCourse =
      course.name.toUpperCase().includes(relationshipName.toUpperCase())

    return isCurrentPartnerCourse
  })
}

export const getCohortBasedCourseData = (courseData, cohortMilestones) => {
  if (!courseData?.chapters?.length ||
    !cohortMilestones?.length) return courseData

  const assignmentIds = cohortMilestones?.map(
    assignment => assignment.datoAssignmentUUID
  )

  courseData.chapters = courseData.chapters
    .filter(chapter => {
      if (chapter.type !== WRITING_ASSIGNMENT) return true
      return assignmentIds.includes(chapter.chapter_uuid)
    })
  return courseData
}

export const getCourseBaseUrl = (courseId, isBeta) => {
  const dashboardUrl = 'https://dashboard.outlier.org'
  if (!courseId) return dashboardUrl

  const courseName = config.courseNames[courseId]
  const isGGUCourse = courseName?.includes('.plus')
  if (!courseName) return dashboardUrl

  return `https://${courseName}.${
    (isBeta && !isGGUCourse) ? `${FIRST_EDITION_SUBDOMAIN}.` : ''
  }outlier.org/#`
}

export const isOrientationChapter = chapter => {
  return chapter.type === ORIENTATION
}

export const isReviewChapter = chapter => {
  return chapter.type === REVIEW
}

export const isNonGradedChapter = (chapter) => {
  return isOrientationChapter(chapter) || isReviewChapter(chapter)
}

export const getCourseIcon = (catalogCourses, courseId) => {
  if (!catalogCourses || catalogCourses.length === 0 || !courseId) return {}
  const catalogCourse = Array.isArray(catalogCourses) &&
    catalogCourses.find(({ uuid }) => {
      return uuid.uuid === courseId
    })

  return catalogCourse?.media?.courseIcon?.url ||
    config.getCourseIcon(courseId)
}

export const getGGUSortedCourses = (courses) => {
  const updatedCourses = sortBy(
    courses.map((course) => ({
      ...course,
      value: course.id,
      label: course.name
    })),
    'name'
  )
  const testCourses = updatedCourses.filter(
    (course) =>
      course.name.toLowerCase().includes('test') || course.name === 'Bundle'
  )
  const mainCourses = updatedCourses.filter(
    (course) => !testCourses.includes(course) && course.name.toUpperCase().includes('GGU')
  )

  return mainCourses
}

export const addCourseInfoToCohort = (coursesRaw = [], { newKey, courseKey } = {}) => {
  if (!coursesRaw?.length) return

  return coursesRaw.map(course => {
    if (!course?.cohorts?.length) return course

    const cohortsWithCourseInfo = course?.cohorts?.map(cohort => ({
      ...cohort,
      ...(newKey ? { [newKey]: course?.[courseKey] } : {})
    }))

    return {
      ...course,
      cohorts: cohortsWithCourseInfo
    }
  })
}

export const getCourseDisplayName = (course) => {
  if (!course) return

  const { name = '' } = course || {}
  const suffixIndex = name?.lastIndexOf(' - ')
  const displayName = suffixIndex !== -1 ? name.slice(0, suffixIndex) : name

  return getNameWithoutVersion(displayName)
}

export const getCourseFilters = enrollmentOptions => {
  const filters = uniq(
    COURSE_ACCESS_TYPES
      .filter(accessType => enrollmentOptions?.includes(accessType.name))
      .map(accessType => (
        JSON.stringify({
          creditGrantingInstitution: accessType.creditGrantingInstitution,
          profCert: accessType.profCert,
          isExtAllowed: accessType.name.includes('Extended')
        })
      ))
  ).map(filter => JSON.parse(filter))

  return filters
}

export const getCoursesForEnrollment = (courses, enrollmentOptions) => {
  if (!courses?.length) return []

  const courseFilters = getCourseFilters(enrollmentOptions)
  const filteredCourses = courses
    .filter(({ creditGrantingInstitution, profCert, name }) => {
      return courseFilters.some(filter => {
        const isExtendedCohortAllowed = filter.isExtAllowed
          ? name.includes('- EXT')
          : !name.includes(' - EXT')
        return (
          filter.creditGrantingInstitution === creditGrantingInstitution &&
          !!filter.profCert === !!profCert &&
          isExtendedCohortAllowed
        )
      })
    })

  return filteredCourses
}

export const getCourseDurations = (course) => {
  if (!course?.cohorts?.length) return []

  const courseDurations = course?.cohorts?.map(cohort => cohort?.duration)
  return uniq(courseDurations)
}

export const getCoursesOptions = (courses) => {
  if (!courses?.length) return []

  const uniqueCourses = uniqBy(courses, 'displayName')
  const coursesOptions = uniqueCourses.map(course => ({
    label: course?.displayName,
    value: course?.displayName
  }))

  const coursesOptionsWithIds = coursesOptions.map(course => {
    return {
      ...course,
      ids: courses
        .filter(c => c?.displayName === course?.value)
        .map(c => ({ courseId: c?.id, durations: getCourseDurations(c) }))
    }
  })

  return coursesOptionsWithIds
}

export const getEnrollmentOptionsDuration = (enrollmentOptions) => {
  if (!enrollmentOptions) return []

  const durations = COURSE_ACCESS_TYPES
    .filter(accessType => enrollmentOptions.includes(accessType.name))
    .map(accessType => accessType.duration)
    .flat().sort((a, b) => b - a)

  return uniq(durations)
}

export const getCourseRules = (courseName, courseDetails) => {
  const courseBlockers = courseDetails.blockers?.map(
    (blocker, index, array) => (
      <Fragment key={blocker.name}>
        <span>{blocker.name}</span>
        {index < array.length - 1 && ' or '}
      </Fragment>
    )
  )

  const blockersMessage = ['Precalculus', 'College Algebra'].includes(
    courseName
  )
    ? (
      <p>
        Students cannot receive credit for both <span>Precalculus</span> and{' '}
        <span>College Algebra</span>. Students cannot take this course if they
        have passed <span>Calculus I</span>.
      </p>
      )
    : (
      <p>
        Students cannot take this course if they have passed {courseBlockers}.
      </p>
      )
  return (
    <>
      {courseDetails.recommendations?.length > 0 && (
        <p>
          We recommend that students take{' '}
          <span>{courseDetails.recommendations[0].name}</span> with Outlier
          before taking <span>{courseName}</span>.
        </p>
      )}

      {courseDetails.prerequisites?.length > 0 && (
        <p>
          Students must pass <span>{courseDetails.prerequisites[0].name}</span>{' '}
          in order to take this course.
        </p>
      )}

      {courseDetails.blockers?.length > 0 && blockersMessage}

      {!!courseDetails.max_attempts && (
        <p>
          University of Pittsburgh allows students to take this course a maximum
          of {courseDetails.max_attempts} times.
        </p>
      )}
    </>
  )
}
