import React, { useState, memo, useCallback, useEffect } from 'react'
import { NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import usePromise from '../../../hooks/usePromise/usePromise'
import chevronRight from '../../../assets/icons/chevron-right.svg'
import getCards from '../../../routes'
import CloseIcon from '../../../assets/icons/modal-close.svg'
import {
  StyledCloseIcon,
  StyledContainer,
  StyledNavBarItem,
  StyledTitle,
  StyledTool
} from './JumpToTool.styled'
import {
  StyledNavigateIcon,
  StyledCardTitle
} from '../../../screens/Dashboard/Dashboard.styled'
import {
  StyledFlex,
  StyledSeparator,
  StyledOverlay
} from '../../global.styled'
import api from '../../../api'
import Loader from '../../Loader/Loader'
import { SPECIAL_MENU_RELATIONSHIPS } from '../../../constants'

const JumpToTool = () => {
  const [isVisible, setIsVisible] = useState(false)

  const {
    data: relationship,
    isLoading: isRelationshipLoading,
    manualPromiseTrigger: fetchRelationship
  } = usePromise(useCallback(() => api.getPartnerRelationships(), []))

  useEffect(() => {
    if (!isVisible) return

    fetchRelationship()

    // eslint-disable-next-line
  }, [isVisible])

  if (isRelationshipLoading) return <Loader size='small' />

  const { relationshipName, enrollmentOptions, liveProctoring } = relationship?.fields || {}

  return (
    <>
      <StyledNavBarItem onClick={() => setIsVisible(true)}>
        Jump To Tool
      </StyledNavBarItem>
      {isVisible &&
       (
         <StyledOverlay>
           <StyledContainer>
             <StyledFlex align='center'>
               <StyledTitle>Jump to tool</StyledTitle>
               <StyledCloseIcon
                 className='ml-auto'
                 onClick={() => setIsVisible(false)}
                 src={CloseIcon} alt='close icon'
               />
             </StyledFlex>
             <StyledSeparator />
             <StyledFlex wrap='wrap'>
               {getCards({ enrollmentOptions, liveProctoring, relationshipName })
                 .map((card, index) => {
                   const { to, excludeFor, externalLink, includeFor } = card
                   if (excludeFor?.includes(relationshipName)) return null
                   if (includeFor && !includeFor.includes(relationshipName)) return null

                   if (to || externalLink) {
                     return (
                       <div
                         key={index}
                         className='col-12 col-sm-6 col-md-4'
                       >
                         <StyledTool id='toolsList'>
                           <NavLink
                             className='d-inline-block'
                             {...(externalLink && { href: externalLink })}
                             {...(to && { to, tag: Link, onClick: () => setIsVisible(false) })}
                             style={{ padding: 0 }}
                           >
                             <StyledFlex align='center'>
                               <img src={card.icon} alt={card.name} />
                               <StyledCardTitle>
                                 {card.name}
                                 <StyledNavigateIcon
                                   src={card.subIcon || chevronRight}
                                   alt='chevron-right'
                                 />
                               </StyledCardTitle>
                             </StyledFlex>
                           </NavLink>
                         </StyledTool>
                       </div>
                     )
                   }

                   if (SPECIAL_MENU_RELATIONSHIPS.includes(relationshipName)) {
                     return (
                       <div
                         key={index}
                         className='col-12 col-sm-6 col-md-4'
                       >
                         <StyledTool id='toolsList'>
                           <StyledFlex align='center'>
                             <img src={card.icon} alt={card.name} />
                             <StyledCardTitle>
                               {card.name}
                               <StyledNavigateIcon
                                 src={card.subIcon || chevronRight}
                                 alt='chevron-right'
                               />
                             </StyledCardTitle>
                           </StyledFlex>
                         </StyledTool>
                       </div>
                     )
                   }

                   return null
                 })}
             </StyledFlex>
           </StyledContainer>
         </StyledOverlay>
       )}
    </>
  )
}

export default memo(JumpToTool)
