import api from '../api'
import moment from 'moment'
import { convertTextToPdf } from './GradingSidebarUtils'
import { getNameWithoutVersion, wait } from './'
import {
  addDotBtwDateAndTime,
  dateToSecondsSinceEpoch,
  formatWithoutYear,
  getTimezoneShort,
  timeStampToFormattedDate
} from './dateTimeUtils'
import { getCohortStartSecondsSinceEpoch } from './cohort'
import { getCourseIds } from '../config'
import _ from 'lodash'

export const getCohortDetails = milestone => {
  if (!milestone) return
  const { dateStart, duration } = milestone
  const paddedCohortDuration = String(duration).padStart(2, '0')
  return `${formatDateStart(dateStart)} - ${paddedCohortDuration}w`
}

export const formatDateStart = date => {
  if (!date) return ''
  const formattedDate = moment.utc(date).format('MM/DD/YY')
  return formattedDate
}

export const getLockTime = (assignment) => {
  if (!assignment) return
  const {
    cohortMilestone: {
      lockTime,
      duration,
      dateStart
    } = {}
  } = assignment

  // if lockTime is empty, student deadline is
  // calculated using [cohort dateStart + (cohort duration * 7) ].
  const newDateStart = new Date(dateStart)
  const totalDuration = duration * 7
  return lockTime ||
    newDateStart.setDate(newDateStart.getDate() + totalDuration)
}

export const getRubric = ({
  rubric,
  rubrics,
  cohortStartDate
}) => {
  if (rubrics?.length) {
    const rubricsWithStartDate = rubrics.map((_rubric) => ({
      ..._rubric,
      startDate: getCohortStartSecondsSinceEpoch({ dateStart: _rubric.cohortStartDate })
    })).sort((a, b) => b.startDate - a.startDate)

    const cohortRubric = rubricsWithStartDate.find(_rubric => cohortStartDate >= _rubric.startDate)
    if (cohortRubric) return cohortRubric.url
  }
  return rubric
}

export const getUpdatedDatoAssignmentDetails = ({
  currentAssignment,
  datoAssignmentDetails
}) => {
  const { dateStart } = currentAssignment

  const {
    assignmentDetails,
    updatedassignmentdetails,
    description,
    updateddescription,
    title,
    updateddisplaytitle,
    maxScore,
    updatedmaxscore,
    updatedRubrics,
    rubric
  } = datoAssignmentDetails

  const cohortStartDate = getCohortStartSecondsSinceEpoch({ dateStart })

  const cohortRubric = getRubric({
    rubric,
    rubrics: updatedRubrics,
    cohortStartDate: cohortStartDate
  })

  return {
    ...datoAssignmentDetails,
    assignmentDetails: updatedassignmentdetails || assignmentDetails,
    description: updateddescription || description,
    title: updateddisplaytitle || title,
    maxScore: getUpdatedMaxScore(updatedmaxscore) || maxScore,
    rubric: cohortRubric
  }
}

export const filteredSubmissions = (submissions, assignmentList) => {
  if (!submissions || !submissions.length) return []
  const assignmentUUIDList = assignmentList.map(assignment => assignment.assignmentUUID)

  submissions = submissions.map(submission => submission.data).flat()
  return submissions.length > 0 && submissions
    .flatMap(submission => {
      const { assignments, ...rest } = submission || {}
      return assignments?.map(submissionAssignment => {
        const { assignmentUUID, cohortId, deadlineTime } = submissionAssignment
        const assignment = assignmentList.find(
          item => {
            return item.assignmentUUID === assignmentUUID &&
              item.cohortMilestone.cohortId === cohortId
          })
        if (!assignment) return null
        const { cohortMilestone, courseId } = assignment
        const cohortDetails = getCohortDetails(cohortMilestone)
        const newLockTime = getLockTime(assignment)
        const cohortDeadline = dateToSecondsSinceEpoch(new Date(newLockTime))
        const deadline = deadlineTime || cohortDeadline

        const updatedAssignment = getUpdatedDatoAssignmentDetails({
          courseId,
          currentAssignment: assignment.cohortMilestone || {},
          datoAssignmentDetails: assignment
        })

        return {
          cohortDeadline,
          cohortDetails,
          deadline,
          courseId,
          ...updatedAssignment,
          ...submissionAssignment,
          ...rest
        }
      })
    })
    .filter(submission =>
      submission &&
      assignmentUUIDList.includes(submission.assignmentUUID) &&
      submission.status === 'submitted'
    )
}

export const getFilteredSubmissions = async ({
  cohorts,
  assignmentList
}) => {
  if (!cohorts?.length) return
  if (!assignmentList || !assignmentList.length) return []

  const promises = cohorts.flatMap(({ courseId, name }) => api.getStudentSubmissions(
    courseId, `cohortName=${encodeURIComponent(name)}`))
  const submissions = (await Promise.all(promises))
  if (!submissions || !submissions.length) return []
  return filteredSubmissions(submissions, assignmentList)
}

export const getCohortIdsOfUngradedAssignments = async (cohorts, courseId) => {
  if (!cohorts.length || !courseId) return []
  // get cohorts that ended in the past 30 days
  const cohortsEndedInPast30Days = cohorts.filter(cohort => {
    const { finalExamEndTime, dateStart, name } = cohort

    const thirtyDaysAgo = moment().add(-30, 'days')

    return moment(finalExamEndTime).isBetween(thirtyDaysAgo, undefined) &&
      moment().isAfter(dateStart) && !name?.includes('Audit')
  })

  // get all assignments of those cohorts
  const promises = cohortsEndedInPast30Days.map(async cohort => (
    api.getStudentSubmissions(
      courseId, `cohortName=${encodeURIComponent(cohort?.name)}`
    )
  ))

  const cohortsAssignments = await Promise.all(promises)

  // get all assignments that are not graded and return cohortIds of those assignments

  if (!cohortsAssignments) return []

  const cohortIdsOfUngradedAssignments = cohortsAssignments.map((submissionData) => {
    let cohortId
    const { data: submissions } = submissionData

    submissions?.forEach(submission => {
      const { assignments } = submission

      if (!assignments.length) return

      cohortId = assignments.find(assignment => {
        const { grade, status } = assignment
        return !grade && grade !== 0 && status === 'submitted'
      })?.cohortId
    })

    return cohortId
  })

  return cohortIdsOfUngradedAssignments.filter(id => id)
}

export const getFormattedMilestones = (cohorts, milestones) => {
  return milestones
    .map(milestone => {
      const { dateStart, duration, cohortName } = milestone || {}
      const cohort = cohorts.find(cohort => cohort.name === cohortName)
      const { endTime, finalExamEndTime } = cohort || {}

      const endTimes = [
        dateToSecondsSinceEpoch(new Date(endTime)),
        dateToSecondsSinceEpoch(new Date(finalExamEndTime))
      ].filter(time => !isNaN(time))
      const latestEndTime = Math.max(...endTimes)

      if (!cohort) return []
      return milestone.milestones.map(m => {
        return {
          ...m,
          cohortId: cohort.at_id,
          dateStart,
          duration,
          cohortName,
          cohortEndTime: latestEndTime
        }
      })
    })
    .flat()
}

export const getGradingStatusFilteredSubmissions = (submissions, selectedGradingStatuses) => {
  if (!submissions || !selectedGradingStatuses) return []
  return submissions
    .map(submission => {
      const isGraded = isSubmissionGraded(submission)
      return { ...submission, isGraded }
    })
    .filter(submission => {
      let result = false
      selectedGradingStatuses.forEach(({ label }) => {
        if (label === 'Grade Pending' && !submission.isGraded) {
          result = true
        }
        if (label === 'Graded' && submission.isGraded) {
          result = true
        }
      })
      return result
    })
}

export const getSubmissionStatusFilteredSubmissions = (submissions, selectedSubmissionStatuses) => {
  if (!submissions || !selectedSubmissionStatuses) return []
  return submissions
    .map(submission => {
      const isLate = isSubmissionLate(submission)
      return { ...submission, isLate }
    })
    .filter(submission => {
      let result = false
      selectedSubmissionStatuses.forEach(({ label }) => {
        if (label === 'On Time' && !submission.isLate) {
          result = true
        }
        if (label === 'Late' && submission.isLate) {
          result = true
        }
      })
      return result
    })
}

export const isSubmissionGraded = submission => {
  const { grade } = submission
  // when an assignment is not graded, grade is undefined
  // when an assignment is graded, then it becomes a number (the grade)
  return typeof grade === 'number'
}

export const isSubmissionLate = submission => {
  const { cohortDeadline, submissionTime } = submission
  // the assignment is late when submissionTime is later than
  // the deadline for the assignment
  // this situation only occurs if the student is given extension on deadline
  return submissionTime > cohortDeadline
}
export const getUpdatedMaxScore = score => {
  if (!score) return null

  return parseInt(score.split(';')[1])
}

export const getCoursesWithCourseId = (courses) => {
  if (!courses) return []
  return courses.filter(course => courseIdToName(course.id))
}

function courseIdToName (id) {
  return invert(getCourseIds())[id]
}

function invert (obj) {
  const inverted = {}
  Object.keys(obj).forEach(function (k) {
    const v = obj[k]
    inverted[v] = k
  })
  return inverted
}

export const getFormattedTime = seconds => {
  if (!seconds) return null
  const timezone = getTimezoneShort(seconds)
  return addDotBtwDateAndTime(
    formatWithoutYear(new Date(seconds * 1000))) + ` ${timezone}`
}

export const fetchDatoAssignmentDetails = async (courseId, assignmentUUID) => {
  const { data: courseData } = await api.getCourseData(courseId)
  return courseData.chapters.find(chapter =>
    chapter.chapter_uuid === assignmentUUID)
}

export const fetchCohortMilestones = async (courseId, cohortName) => {
  const { data: cohorts } = await api.getAllCohorts({ courseId })
  const currentCohort = cohorts.find(cohort => cohort.name === cohortName)
  if (!currentCohort) return null
  const {
    data: currentCohortMilestone
  } = await api.getCohortMilestones(currentCohort.at_id)
  if (!currentCohortMilestone) return null

  return getFormattedMilestones(cohorts, [currentCohortMilestone])
}

export const getCurrentAssignment = async ({
  courseId,
  assignmentUUID,
  cohortName
}) => {
  const datoAssignmentDetails = await fetchDatoAssignmentDetails(courseId, assignmentUUID)
  if (!datoAssignmentDetails) return null
  const airtableAssignmentMilestones = await fetchCohortMilestones(courseId, cohortName)
  if (!airtableAssignmentMilestones) return null

  const currentAssignment = airtableAssignmentMilestones.find(
    milestone => (milestone.datoAssignmentUUID === assignmentUUID &&
      milestone.cohortName === cohortName))

  if (!currentAssignment) return null

  const updatedDatoAssignmentDetails = getUpdatedDatoAssignmentDetails({
    courseId,
    currentAssignment,
    datoAssignmentDetails
  })

  return {
    assignmentUUID,
    label: updatedDatoAssignmentDetails.title,
    value: updatedDatoAssignmentDetails.chapter_uuid,
    cohortMilestone: { ...currentAssignment },
    ...updatedDatoAssignmentDetails
  }
}

export const getSubmissionTime = seconds => {
  if (!seconds) return null
  const submissionTime = timeStampToFormattedDate(seconds * 1000, true, 'short')
  return submissionTime && submissionTime.replace(/(,[^,]*),/g, '$1')
}

export const getStudentGrade = (grade, maxScore) => {
  if (!grade && grade !== 0) return
  const studentGrade = Math.round(((grade / 100) * maxScore) * 100) / 100

  return `${studentGrade}/${maxScore}`
}

export const getGradedAssignments = assignmentProgress => {
  const gradedAssignments = []
  if (!assignmentProgress) return gradedAssignments

  Object.keys(assignmentProgress).forEach(assignmentUUID => {
    const currentAssignmentProgress = assignmentProgress[assignmentUUID]
    // by default grade key is absent, it becomes a number after grading
    if (typeof assignmentProgress[assignmentUUID].grade === 'number') {
      gradedAssignments.push({
        ...currentAssignmentProgress,
        assignmentUUID
      })
    }
  })

  return gradedAssignments
}

export const getKamiDocumentStatus = async (urlParams, documentIdentifier) => {
  let kamiResponse = {}
  let isUploadingDocument = true

  while (isUploadingDocument) {
    await wait(2000)

    try {
      const { data } = await api.getKamiDocument(
        { ...urlParams, documentIdentifier }
      )

      const { file_status: fileStatus } = data
      if (['done', 'error'].includes(fileStatus)) {
        kamiResponse = data
        isUploadingDocument = false
      }
    } catch (error) {
      kamiResponse = {
        file_status: 'error'
      }
      isUploadingDocument = false
    }
  }

  return kamiResponse
}

export const getKamiDocumentData = ({
  assignment, assignmentUUID, assignmentFileMetadata
}) => {
  const assignmentData = Array.isArray(assignment) ? assignment?.[0] : assignment
  const {
    data, headers: { 'content-type': fileType } = {}
  } = assignmentData || {}
  const fileMetadata = assignmentFileMetadata[assignmentUUID]
  let { originalFileName } = fileMetadata || {}
  let blob

  if (fileType === 'application/json' || fileType === 'text/plain') {
    const doc = convertTextToPdf(data, fileType)
    blob = doc.output('blob')
    originalFileName = `${assignmentUUID}.pdf`
  } else {
    blob = new Blob([data], { type: fileType })
  }

  const formData = new FormData()
  formData.append('kami-assignment-file', blob, originalFileName)

  return formData
}

export const getKamiViewSessionUrl = async ({
  documentIdentifier, nickname, sub, encodedStudentEmail
}) => {
  const expiryDate = new Date(
    new Date().setHours(new Date().getHours() + 24)
  ).toISOString() // after 24 hours
  const body = {
    document_identifier: documentIdentifier,
    user: {
      name: nickname,
      user_id: sub.slice(0, -3)
    },
    expires_at: expiryDate,
    editable: true
  }

  const { data, errorMessage } = await api.createKamiViewSession(encodedStudentEmail, body)

  return {
    viewSessionUrl: data?.viewer_url,
    viewSessionError: errorMessage
  }
}

export const uploadToKamiAndGetStatus = async ({
  encodedStudentEmail,
  courseId,
  cohortId,
  assignment,
  assignmentUUID,
  assignmentFileMetadata,
  nickname,
  sub
}) => {
  const formData = getKamiDocumentData({
    assignment, assignmentUUID, assignmentFileMetadata
  })
  if (!formData) return

  const urlParams = {
    studentEmail: encodedStudentEmail, courseId, cohortId, assignmentUUID
  }
  const { data: putKamiResponse, errorMessage } = await api.putKamiDocument(
    formData, urlParams
  )

  if (errorMessage) {
    return { isError: true, isLoading: false }
  }

  const {
    document_identifier: documentIdentifier, file_status: oldFileStatus
  } = putKamiResponse || {}

  let kamiResponse = putKamiResponse
  const isUploadingDocument = oldFileStatus !== 'done'

  if (isUploadingDocument) {
    const getKamiResponse = await getKamiDocumentStatus(urlParams, documentIdentifier)
    kamiResponse = getKamiResponse
  }

  const { file_status: newFileStatus } = kamiResponse

  if (newFileStatus === 'error') {
    return { isError: true, isLoading: false }
  }

  const { viewSessionUrl, viewSessionError } = await getKamiViewSessionUrl({
    documentIdentifier, nickname, sub, encodedStudentEmail
  })
  if (viewSessionError) return { isError: true, isLoading: false }

  window.open(viewSessionUrl, '_blank')
  return { isError: false, isLoading: false }
}

const sharedFields = ['at_id', 'id']
const mergedFields = ['cohorts', 'writingAssignments']

export function mergeCoursesWithVersions (courses) {
  if (!courses?.length) return []

  const mergedCourses = {}
  const coursesCopy = _.cloneDeep(courses)

  coursesCopy.forEach(course => {
    mergedFields.forEach(field => {
      course[field]?.forEach(item => { item.courseId = course?.id })
    })

    const courseNameWithoutVersion = getNameWithoutVersion(course?.name)
    const existingCourse = mergedCourses[courseNameWithoutVersion]

    if (!existingCourse) {
      mergedCourses[courseNameWithoutVersion] = course
    } else {
      const existingCourseCopy = _.cloneDeep(existingCourse)
      existingCourseCopy.name = courseNameWithoutVersion

      sharedFields.forEach(field => {
        existingCourseCopy[field] = [existingCourse[field], course[field]]
      })

      mergedFields.forEach(field => {
        existingCourseCopy[field] = existingCourseCopy[field].concat(course[field])
      })

      mergedCourses[courseNameWithoutVersion] = existingCourseCopy
    }
  })

  return Object.values(mergedCourses)
}
