import React from 'react'
import { Spinner } from './LoadingSpinner.styled'

export default function LoadingSpinner ({ position, marginTop, marginLeft }) {
  return (
    <Spinner
      position={position}
      marginTop={marginTop}
      marginLeft={marginLeft}
      data-testid='loadingSpinner'
    >
      <div className='bounce1' />
      <div className='bounce2' />
      <div />
    </Spinner>
  )
}
