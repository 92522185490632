import styled from 'styled-components'

export const StyledWrapper = styled.div`
  height: 100vh;
  background: var(--canvas-primary);
  color: var(--text-primary);
`

export const StyledContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const StyledSpan = styled.span`
  font-size: 18px;
`
