import { useCallback, useEffect } from 'react'
import useSafeState from '../useSafeState/useSafeState'

const usePromise = (promise, options) => {
  const {
    dependencies,
    defaultLoading
  } = options || {}

  const [response, setResponse] = useSafeState({
    isLoading: defaultLoading,
    data: null,
    error: null
  })

  const executePromise = useCallback(async (...args) => {
    setResponse(prevResponse => ({
      ...prevResponse,
      isLoading: true
    }))

    const { data, error } = await promise(...args) || {}

    setResponse(prevResponse => ({
      ...prevResponse,
      data,
      error,
      isLoading: false
    }))
  }, [promise, setResponse])

  useEffect(() => {
    if (dependencies) executePromise()
    // eslint-disable-next-line
  }, dependencies || [])

  return {
    ...response,
    manualPromiseTrigger: executePromise
  }
}

export default usePromise
