import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import AppGuard from './components/AppGuard/AppGuard'
import Auth0Provider from './components/Auth0Provider/Auth0Provider'
import reportWebVitals from './reportWebVitals'

import 'react-datepicker/dist/react-datepicker.css'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Auth0Provider>
        <Router>
          <AppGuard>
            <App />
          </AppGuard>
        </Router>
      </Auth0Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
