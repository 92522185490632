export const SET_COURSES_WITH_ASSIGNMENTS = 'set-courses-with-assignments'
export const SET_SELECTED_COURSE = 'set-selected-course'
export const SET_SELECTED_GRADING_STATUSES = 'set-selected-grading-statuses'
export const SET_SELECTED_SUBMISSION_STATUSES = 'set-selected-submission-statuses'
export const SET_SELECTED_ASSIGNMENTS = 'set-selected-assignments'
export const SET_DEFAULT_COHORTS = 'set-default-cohorts'
export const SET_SELECTED_COHORTS = 'set=selected-cohorts'
export const SET_ACTIVE_COHORTS_SELECTED = 'set-active-cohorts-selected'
export const SET_PAST_COHORTS_SELECTED = 'set-past-cohorts-selected'
export const SET_ALL_ASSIGNMENTS = 'set-all-assignments'
export const SET_FILTERED_SUBMISSIONS = 'set-filtered-submissions'
export const SET_CURRENT_ASSIGNMENT = 'set-current-assignment'
export const SET_STUDENTS_BY_COHORT = 'set-students-by-cohort'
export const SET_SUBMISSIONS_ASCENDING = 'setSubmissionsAscending'

export const GRADING_STATUS = [
  { label: 'Grade Pending', value: false },
  { label: 'Graded', value: true }
]

export const SUBMISSION_STATUS = [
  { label: 'Late', value: false },
  { label: 'On Time', value: true }
]

export const HEADERS = [
  { title: 'Cohort', sortItem: 'cohortDetails', disableSort: false },
  { title: 'Assignment', sortItem: 'title', disableSort: false },
  { title: 'Last Name', sortItem: 'lastName', disableSort: false },
  { title: 'First Name', sortItem: 'firstName', disableSort: true },
  { title: 'Deadline', sortItem: 'deadline', disableSort: false },
  { title: 'Submission Time', sortItem: 'submissionTime', disableSort: false }
]

export const PAGINATION_OPTIONS = [
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
  { label: 250, value: 250 }
]

export const NO_ASSIGNMENT = 'Student did not submit assignment.'
export const WRITING_ASSIGNMENT_GRADED_GGU = 'Writing Assignment Graded GGU'
