import React, { memo } from 'react'
import { Route } from 'react-router-dom'
import MainLayout from '../Layout/MainLayout'
import Loader from '../Loader/Loader'

const renderRoute = (Component, routeProps) => {
  return (
    <MainLayout>
      <React.Suspense fallback={<Loader />}>
        <Component {...routeProps} />
      </React.Suspense>
    </MainLayout>
  )
}

const PublicRoute = ({ component, ...rest }) => {
  return <Route {...rest} render={renderRoute.bind(null, component)} />
}

export default memo(PublicRoute)
