import styled from 'styled-components'
import mediaQueries from '../../../mediaQueries'

export const StyledNavBarItem = styled.div`
  margin-right: 24px;
  cursor: pointer;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  ${mediaQueries.small`
    display: none;
  `}
`

export const StyledContainer = styled.div`
  margin: 68px auto;
  max-width: 1072px;
  @media (max-width: 1072px) {
    padding: 0 16px;
  }
`
export const StyledTitle = styled.h2`
  color: var(--text-primary);
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
`
export const StyledCloseIcon = styled.img`
  height: 16px;
  width: 16px;
  vertical-align: middle;
  cursor: pointer;
`

export const StyledTool = styled.div`
  padding: 8px 0;
  border-radius: 5px;
  margin-bottom: 16px;
  &:hover{
    text-decoration: none !important;
  }
`
