import inRange from 'lodash/inRange'
import mergeWith from 'lodash/mergeWith'
import { customMerge } from '.'
import { EXAM, ESSAY, SECTION, BREAK } from '../constants/chapterTypes'
import { INTENSIVE_COHORT_DURATION } from '../constants/cohort'
import { SET_UP_YOUR_EXAM } from '../constants/examTypes'
import { getAssignmentName } from './cohort'
import {
  addBusinessDays,
  getMidnight,
  MONTH_DATE_FORMAT,
  getFormattedDate,
  getTimestamp,
  convertEasternTimeToLocalTime,
  getFormattedEndDate,
  getWeekDay,
  DEADLINE_FORMAT,
  subtractDuration,
  addDuration,
  DATE_STRING_FORMAT
} from './dateTimeUtils'
import moment from 'moment'

export const openLink = url => window.open(url, '_blank')

export const getDropWithdrawalSchedule = params => {
  if (!params?.startDate) return []

  const { startDate, title, text, redirectURL } = params || {}
  const startTimestamp = getTimestamp(startDate)
  const endTimestamp = addDuration({
    startDate,
    inputFormat: '',
    durationType: 'minutes',
    durationValue: (24 * 60) - 1,
    returnTimestamp: true
  })
  return [{
    title,
    textLink: {
      text,
      handleClick: () => { window.location.href = redirectURL }
    },
    startDate: getFormattedDate(startDate, { format: MONTH_DATE_FORMAT }),
    startTimestamp,
    endTimestamp
  }]
}

export const getFirstScheduleItem = (dateStart, courseBaseUrl) => {
  const startDate = getFormattedDate(dateStart, { format: MONTH_DATE_FORMAT })
  const dateEnd = getWeekDay({
    dateStart,
    increment: 1,
    requiredDay: 0,
    time: { hour: 23, minute: 59, second: 0 }
  }).toDate()

  return {
    startDate,
    startTimestamp: getTimestamp(dateStart),
    title: 'Semester Begins',
    endDate: getFormattedEndDate(dateStart, dateEnd),
    endTimestamp: dateEnd.getTime(),
    textLink: {
      text: 'Watch the Orientation',
      handleClick: () => openLink(courseBaseUrl)
    }
  }
}

export const getSpecialDaysSchedule = specialDays => {
  if (!specialDays?.length) return []

  return specialDays.map(specialDay => {
    const {
      dayStart,
      dayEnd,
      name: title
    } = specialDay.fields || {}

    return {
      startTimestamp: getTimestamp(dayStart),
      startDate: getFormattedDate(dayStart, { format: MONTH_DATE_FORMAT }),
      endDate: getFormattedEndDate(dayStart, dayEnd),
      endTimestamp: getTimestamp(dayEnd),
      textLink: ' ',
      title
    }
  })
}

export const getSectionTitle = title => {
  return title?.split('|')?.pop()?.trim() || ''
}

export const getExamDates = (cohort, title) => {
  const {
    midTerm1StartTime,
    midTerm1EndTime,
    midTerm2StartTime,
    midTerm2EndTime,
    finalExamStartTime,
    finalExamEndTime
  } = cohort?.fields || {}

  if (['Midterm', 'Midterm 1'].includes(title)) {
    return { startDate: midTerm1StartTime, endDate: midTerm1EndTime }
  }

  if (title === 'Midterm 2') {
    return { startDate: midTerm2StartTime, endDate: midTerm2EndTime }
  }

  if (title === 'Final Exam') {
    return { startDate: finalExamStartTime, endDate: finalExamEndTime }
  }

  return {}
}

export const getExamDatesFromCohortExamDates = (cohort, exam) => {
  const {
    midTerm1StartTime,
    midTerm1EndTime,
    midTerm2StartTime,
    midTerm2EndTime,
    finalExamStartTime,
    finalExamEndTime
  } = cohort?.fields || {}

  const { examNumber, isFinalExam } = exam

  if (isFinalExam && finalExamStartTime && finalExamEndTime) {
    return {
      startDate: finalExamStartTime,
      endDate: finalExamEndTime
    }
  }

  // Existing Midterm 1
  if (examNumber === 1) {
    return {
      startDate: midTerm1StartTime,
      endDate: midTerm1EndTime
    }
  }

  // Existing Midterm 2
  if (examNumber === 2) {
    return {
      startDate: midTerm2StartTime,
      endDate: midTerm2EndTime
    }
  }

  // Other exams template will be: exam{examNumber}StartDate and exam{examNumber}EndDate
  // Example: exam3StartDate and exam3EndDate
  const examStartTimeKey = `exam${examNumber}StartTime`
  const examEndTimeKey = `exam${examNumber}EndTime`

  return {
    startDate: cohort?.fields?.[examStartTimeKey],
    endDate: cohort?.fields?.[examEndTimeKey]
  }
}

export const getPracticeExam = (startDate, courseBaseUrl) => {
  if (!startDate) return null

  const examStartDate = subtractDuration({
    startDate,
    inputFormat: '',
    durationType: 'weeks',
    durationValue: 1,
    returnTimestamp: true
  })
  const examEndDate = addDuration({
    startDate: examStartDate,
    inputFormat: '',
    durationValue: 6,
    returnTimestamp: true
  })

  return {
    title: SET_UP_YOUR_EXAM,
    startTimestamp: examStartDate,
    startDate: getFormattedDate(examStartDate, { format: MONTH_DATE_FORMAT }),
    endDate: getFormattedEndDate(examStartDate, examEndDate),
    endTimestamp: examEndDate,
    textLink: {
      text: isActiveSection(examStartDate, examEndDate) && 'start exam',
      handleClick: () => window.open(courseBaseUrl, '_self')
    }
  }
}

export const isActiveSection = (startTime, endTime) => {
  return inRange(getMidnight().valueOf(), startTime, endTime + 1)
}

export const getExamSchedule = ({
  exams, cohort, courseBaseUrl, liveProctoring
}) => {
  const examsSchedule = exams?.map(exam => {
    const { title } = exam || {}
    const sectionTitle = getSectionTitle(title)
    const { startDate, endDate } = getExamDatesFromCohortExamDates(cohort, exam)
    if (!startDate && !endDate) return null

    const startTimestamp = getTimestamp(startDate)
    const endTimestamp = getTimestamp(endDate)
    return {
      ...exam,
      startTimestamp,
      startDate: getFormattedDate(
        startDate, { showTimezone: true, format: DEADLINE_FORMAT }
      ),
      endDate: getFormattedEndDate(
        startDate, endDate, { showTimezone: true, returnFormat: DEADLINE_FORMAT }
      ),
      endTimestamp,
      textLink: {
        text: isActiveSection(startTimestamp, endTimestamp) && 'start exam',
        handleClick: () => window.open(courseBaseUrl, '_self')
      },
      title: sectionTitle
    }
  })
  const practiceExam = getPracticeExam(
    cohort?.fields?.midTerm1StartTime, courseBaseUrl
  )
  const schedule = liveProctoring
    ? examsSchedule
    : [practiceExam, ...examsSchedule]
  return schedule.filter(Boolean)
}

export const getSectionByType = (syllabusData, type) => {
  if (!type) return syllabusData

  return syllabusData?.filter(section => {
    return section.assignmentType === type
  }) || []
}

export const addScheduleToSections = syllabusData => {
  return syllabusData?.map(data => {
    const { sections, ...rest } = data || {}
    return sections?.map(section => {
      return { ...section, ...rest }
    })
  }).flat()
}

export const getEssaysSchedule = (
  essays, cohortMilestones, chapters, courseBaseUrl
) => {
  if (!essays?.length || !cohortMilestones?.length) return []

  return essays.map(essay => {
    const currentEssay = cohortMilestones.find(milestone => {
      const { assignmentList } = milestone || {}
      if (!assignmentList) return false
      return assignmentList.map(list => list.name).includes(essay.title)
    })
    if (!currentEssay) return null

    const {
      lockTime, unlockTime, description, assignmentList, assignmentType, name
    } = currentEssay
    let essayTitle = getSectionTitle(assignmentList?.[0]?.name)
    if (!essayTitle) essayTitle = getAssignmentName(assignmentType || name)
    const startTimestamp = getTimestamp(unlockTime)
    const endTimestamp = getTimestamp(lockTime)
    const { chapter_uuid: chapterUuid } = chapters?.find(
      chapter => currentEssay.datoAssignmentUUID === chapter.chapter_uuid
    ) || {}

    return {
      ...essay,
      description,
      startDate: getFormattedDate(unlockTime, { format: MONTH_DATE_FORMAT }),
      startTimestamp,
      endDate: getFormattedEndDate(unlockTime, lockTime),
      endTimestamp,
      title: essayTitle,
      textLink: {
        text: isActiveSection(startTimestamp, endTimestamp) && 'View Assignment',
        handleClick: () => window.open(
          `${courseBaseUrl}/${chapterUuid}/writing_assignment`
        )
      }
    }
  }).filter(Boolean)
}

export const getSectionsSchedule = (syllabusByWeek, duration, dateStart) => {
  const sections = syllabusByWeek.map(schedule => {
    const { sections: scheduleSections, ...rest } = schedule || {}
    const sections = scheduleSections?.filter(s => s.assignmentType === SECTION)
    if (!sections?.length) return null

    let newSchedule = {}
    sections.forEach(section => {
      const sectionNumber = section.title?.split(' | ')?.at(-1) || ''
      newSchedule = mergeWith(newSchedule, section, customMerge)
      if (!newSchedule.sections?.length) newSchedule.sections = []
      newSchedule.sections.push(sectionNumber)
    })

    const startTitle = getSectionTitle(sections?.[0]?.title)
    const endTitle = getSectionTitle(sections?.[sections.length - 1]?.title)
    newSchedule.title = sections.length > 1
      ? `Sections ${startTitle} - ${endTitle}`
      : `Section ${startTitle}`

    return { ...rest, ...newSchedule }
  }).filter(Boolean)

  return duration <= INTENSIVE_COHORT_DURATION
    ? getIntensiveSchedule(sections, dateStart)
    : getStandardSchedule(sections, dateStart)
}

export const getIntensiveSchedule = (sections, startDate) => {
  if (!sections?.length || !startDate) return []

  return sections.map(currSchedule => {
    const sectionStartDate = addBusinessDays({
      startDate,
      durationValue: currSchedule.day - 1
    })?.valueOf()

    const endTimestamp = addDuration({
      startDate: sectionStartDate,
      durationValue: 1,
      returnTimestamp: true
    })

    return {
      ...currSchedule,
      startDate: getFormattedDate(sectionStartDate, { format: MONTH_DATE_FORMAT }),
      startTimestamp: sectionStartDate,
      endDate: getFormattedEndDate(sectionStartDate, endTimestamp),
      endTimestamp
    }
  })
}

export const getStandardSchedule = (sections, startDate) => {
  if (!sections?.length || !startDate) return []

  const SUNDAY = 0
  const MONDAY = 1

  const isSunday = new Date(startDate).getDay() === SUNDAY
  const modifiedStartDate = isSunday
    ? moment(startDate).add(1, 'days').format(DATE_STRING_FORMAT)
    : startDate

  return sections.map(currSchedule => {
    const { week } = currSchedule
    const startTimestamp = week === 1
      ? getTimestamp(startDate)
      : getWeekDay({
        dateStart: modifiedStartDate,
        increment: week - 1,
        requiredDay: MONDAY
      }).valueOf()
    const endTimestamp = getWeekDay({
      dateStart: week === 1 ? getTimestamp(modifiedStartDate) : startTimestamp,
      increment: 1,
      requiredDay: SUNDAY
    }).valueOf()

    return {
      ...currSchedule,
      startDate: getFormattedDate(startTimestamp, { format: MONTH_DATE_FORMAT }),
      startTimestamp,
      endDate: getFormattedEndDate(startTimestamp, endTimestamp),
      endTimestamp
    }
  })
}

export const getFormattedTermBreaks = (
  termBreaks, duration, dateStart
) => {
  const isIntensive = duration <= INTENSIVE_COHORT_DURATION
  if (!termBreaks?.length) return []

  const formattedBreaks = termBreaks.reduce((breaks, currentBreak) => {
    const { title, week, day } = currentBreak || {}
    const dates = isIntensive
      ? getIntensiveSchedule([currentBreak], dateStart)
      : getStandardSchedule([currentBreak], dateStart)
    const {
      startDate,
      startTimestamp,
      endDate,
      endTimestamp
    } = dates?.[0] || {}

    const previousBreak = breaks[breaks.length - 1]
    const isConsecutiveBreak = previousBreak?.week === week - 1 ||
        previousBreak?.day === day - 1
    if (isConsecutiveBreak) {
      previousBreak.endDate = endDate
      previousBreak.endTimestamp = endTimestamp
      if (!isNaN(week)) previousBreak.week = week
      else if (!isNaN(day)) previousBreak.day = day
      breaks[breaks.length - 1] = previousBreak
      return breaks
    }

    breaks.push({
      ...currentBreak,
      startDate,
      startTimestamp,
      endDate,
      endTimestamp,
      title
    })
    return breaks
  }, [])

  return formattedBreaks
}

export const getSyllabusData = ({
  courseData, syllabus, cohort, courseBaseUrl, liveProctoring
}) => {
  const {
    fields: {
      duration,
      cohortMilestones,
      dateStart,
      finalDropDate,
      finalWithdrawalDate,
      specialDays
    } = {}
  } = cohort
  const cohortSyllabus = addExamNumbersToCohortSyllabus(syllabus)
  const { syllabusData: syllabusByWeek } = cohortSyllabus || {}
  const cohortStartDate = convertEasternTimeToLocalTime(dateStart, 'T00:00:00')
  const dropSchedule = getDropWithdrawalSchedule({
    startDate: finalDropDate,
    title: 'Last day to drop or audit',
    text: 'Check Eligibility',
    redirectURL: 'https://dashboard.outlier.org/#/course-exit'
  })
  const withdrawSchedule = getDropWithdrawalSchedule({
    startDate: finalWithdrawalDate,
    title: 'Last day to withdraw with a grade of W',
    text: 'Contact Outlier',
    redirectURL: 'https://dashboard.outlier.org/#/contact'
  })
  const specialDaysSchedule = getSpecialDaysSchedule(specialDays)
  const syllabusData = addScheduleToSections(syllabusByWeek)
  const exams = getSectionByType(syllabusData, EXAM)
  const examSchedule = getExamSchedule({
    exams,
    cohort,
    courseBaseUrl,
    liveProctoring
  })
  const essays = getSectionByType(syllabusData, ESSAY)
  const essaysSchedule = getEssaysSchedule(
    essays, cohortMilestones, courseData?.chapters, courseBaseUrl
  )
  const sectionsSchedule = getSectionsSchedule(syllabusByWeek, duration, dateStart)

  const termBreaks = getSectionByType(syllabusData, BREAK)
  const termBreaksSchedule = getFormattedTermBreaks(termBreaks, duration, dateStart)

  const allSchedules = [
    ...termBreaksSchedule,
    ...dropSchedule,
    ...withdrawSchedule,
    ...sectionsSchedule,
    ...specialDaysSchedule,
    ...examSchedule,
    ...essaysSchedule
  ].sort((a, b) => a.startTimestamp - b.startTimestamp)

  const firstScheduleItem = getFirstScheduleItem(cohortStartDate, courseBaseUrl)
  return [firstScheduleItem, ...allSchedules]
}

export const getActiveSchedulesCount = schedules => {
  if (!schedules) return 0

  return schedules.filter(schedule =>
    inRange(
      getMidnight().valueOf(),
      schedule.startTimestamp,
      schedule.endTimestamp + 1
    )
  ).length
}

const getNumberOfExamsFromCohortSyllabus = (syllabus) => {
  if (!syllabus?.syllabusData) return 0

  const { syllabusData } = syllabus

  let examNumber = 0

  syllabusData.forEach(week => {
    const { sections = [] } = week

    sections.forEach(section => {
      const { assignmentType } = section
      if (assignmentType !== 'exam') return

      examNumber += 1
    })
  })

  return examNumber
}

export const addExamNumbersToCohortSyllabus = (syllabus) => {
  if (!syllabus?.syllabusData) return syllabus
  const { syllabusData } = syllabus

  let examNumber = 0
  const numberOfExams = getNumberOfExamsFromCohortSyllabus(syllabus)

  const updatedSyllabusData = syllabusData.map(week => {
    const { sections = [] } = week

    const updatedSections = sections.map(section => {
      const { assignmentType } = section
      if (assignmentType !== 'exam') return section

      examNumber += 1

      return {
        ...section,
        examNumber,
        // All 39wk courses have more than one exam
        isFinalExam: examNumber === numberOfExams
      }
    })

    return {
      ...week,
      sections: updatedSections
    }
  })

  return {
    ...syllabus,
    syllabusData: updatedSyllabusData
  }
}
