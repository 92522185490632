import styled, { css, createGlobalStyle } from 'styled-components'
import mediaQueries from '../mediaQueries'

export const VerticalScrollbarStyles = css`
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--faint-gray);
    width: 8px;
    border-radius: 4px;
    z-index: 10;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
export const HorizontalScollbarStyles = css`
   /* width */
   ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--faint-gray);
    height: 8px;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-secondary);
  }
`

export const OverrideStyles = createGlobalStyle`
  #root {
    height: 100vh;
    overflow: hidden;
  }

  #main-content {
    padding: 28px 64px;
    min-width: 375px;
    box-sizing: border-box;

    ${mediaQueries.small`
      padding: 28px 24px;
    `}

    ${props => props.styleVerticalScrollBar && VerticalScrollbarStyles}
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 1440px;
  margin: 0px auto;
  padding-bottom: 50px;
`

export const StyledSeparator = styled.hr`
  margin-top: ${props => props.mt || 30}px;
  margin-bottom: ${props => props.mb || 31}px;
  height: 1px;
  width: ${props => props.width || 100}%;
  box-sizing: border-box;
  border-color: ${props => props.color || 'var(--faint-gray)'};
`

export const StyledFlex = styled.div`
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.dir || 'row'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  gap: ${props => props.gap || 0}px;
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
  margin-right: ${props => props.mr || 0}px;
  margin-left: ${props => props.ml || 0}px;
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;

  padding-right: ${props => props.pr || 0}px;
  padding-left: ${props => props.pl || 0}px;
  padding-top: ${props => props.pt || 0}px;
  padding-bottom: ${props => props.pb || 0}px;
`

export const StyledOverlay = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--canvas-dark);
  z-index: 9;
  @media (max-width: 576px) {
    height: fit-content;
  }
`

export const font18by24 = css`
  font-size: 18px;
  line-height: 24px;
`

export const font18by22 = css`
  font-size: 18px;
  line-height: 22px;
`
export const font18x22x400 = css`
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
`

export const font14x17x400 = css`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
`

export const font14x14x700 = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`

export const font14x16x700 = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
export const font14x16x800 = css`
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
`
export const font14x17x700 = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`
export const font14x22x700 = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`

export const font14x24x400 = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

export const font16x14x400 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
`

export const font16x14x700 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
`

export const font16x15x400 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
`

export const font16x16x400 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`
export const font16x16x700 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
`

export const font16x19x400 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`

export const font16x19x700 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`
export const font16x22x400 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`
export const font16x22x700 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`

export const font16x24x400 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const font16x24x700 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`

export const font18x24x300 = css`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`
export const font18x21x300 = css`
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
`

export const font18x24x400 = css`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`
export const font18x22x700 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`
export const font18x24x700 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`
export const font18x26X700 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`

export const font22x26x400 = css`
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
`

export const font32x38x300 = css`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
`
export const font32x48x300 = css`
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
`

export const font35x42x300 = css`
  font-weight: 300;
  font-size: 35px;
  line-height: 42px;
`

export const font24x32x300 = css`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
`

export const font40x48x300 = css`
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
`

export const smallText = css`
  ${font18by24}
  font-weight: 400;
`

export const smallText300 = css`
  ${font18by24}
  font-weight: 300;
`

export const smallText14 = css`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--sub-text-secondary);
`

export const StyledTooltip = styled(StyledFlex)`
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  margin-bottom: 8px;
`

export const StyledTooltipText = styled.span`
  margin-right: 8px;
  color: var(--text-secondary);
`

export const StyledWidget = styled(StyledFlex)`
  background: var(--black-secondary);
  border-radius: 5px;
  padding: 16px;
  flex-grow: 1;
`

export const StyledHiddenInput = styled.input`
  display: none;
`
export const LinkStyles = css`
  color: var(--brand-regular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  :hover {
    color: var(--brand-regular-hover);
  }
  :active {
    color: var(--brand-regular-active);
  }

`
export const TitleStyles = css`
  font-style: normal;
  font-weight:var(--content-font-weight);
  color: var(--sub-text-secondary);
  font-size: 32px;
  line-height: 48px;
`
export const SubtitleStyles = css`
  font-style: normal;
  font-weight: var(--content-font-weight);
  font-size: 24px;
  line-height: 29px;
  color: var(--text-primary);
`
export const InfoStyles = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--sub-text-secondary);
`

export const StyledButtonGroup = styled(StyledFlex)`
  ${mediaQueries.medium`
    flex-direction: column;
  `}
`

export const StyledButtonWrapper = styled.div`
  margin-right: ${props => props.mr ?? 10}px;
  ${mediaQueries.medium`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 0px;
    margin-bottom: 12px;
  `}
`
