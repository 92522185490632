import styled from 'styled-components'
import { font14x17x700 } from '../global.styled'

export const DropDownMenuWrapper = styled.div`
  & div {
    &#dropdownMenuOffset {
      cursor: pointer;
    }
  }

  & span {
    &.label {
      margin-left: 8px;
      color: var(--brand-regular);
      ${font14x17x700};
      text-transform: uppercase;
    }
  }
`
export const DropDownMenu = styled.div`
  position: absolute;
  right:0;
  top: 32px;
  min-width: 300px;
  background: var(--gray-primary);
  box-shadow: 0 4px 20px var(--shadow-primary);
  border-radius: 5px;
  color: var(--text-primary);
  padding: 8px 0 8px 0;
  z-index: 5;
  .dropdown-header{
    color: var(--sub-text-secondary) !important;
  }
  .dropdown-item{
    padding-left: 24px !important;
    font-size: 16px;
    color: var(--text-primary) !important;
  }
`
