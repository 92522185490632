import { Navbar } from 'reactstrap'
import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
.navbar-light .navbar-brand {
  color: var(--text-primary);
  font-size: var(--header-font-size);
  font-weight: var(--bold-font-weight);
  padding: 8px 0;
}

.navbar-light .navbar-brand:hover {
  color: var(--text-primary);
}

.navbar {
  background: var(--canvas-dark);
  height: 64px;
}

div.admin-logo{
  display: flex !important;
  line-height: 29px;
}

.logout-btn {
  background: transparent;
  border: 2px solid var(--border-primary);
  border-radius: 0;
  color: var(--text-primary);
  text-transform: uppercase;
}

.logout-btn:hover {
  background: var(--text-primary);
}
`

export const Container = styled.div`
  .navbar {
    background: var(--canvas-dark);
    padding: 0 24px;
  }

  @media (min-width: 576px){
    .navbar-nav{
      flex-direction: row;
    }
  }
  
  .admin-logo {
    padding: 0;
    img{
       height: 24px;
    }
  }
`

export const StyledNavBar = styled(Navbar)`
  .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
  }
`

export const StyledHorizontalRuler = styled.hr`
  height: 1px;
  border-color: var(--border-primary);
  margin-top: 8px;
  margin-bottom: 8px;
`
