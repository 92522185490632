import createAuth0Client from '@auth0/auth0-spa-js'

let client

export default getClient

async function getClient () {
  if (client) return client

  client = await createAuth0Client({
    domain: process.env.REACT_APP_DOMAIN || 'outlier.auth0.com',
    client_id: process.env.REACT_APP_CLIENT_ID ||
    'YglNLrKtTjW0CQvax9bUqhDJD0N5rIEZ',
    responseType: 'token id_token'
  })

  client.getIdToken = async function () {
    await client.getTokenSilently()
    const claims = await client.getIdTokenClaims()
    return claims.__raw
  }

  return client
}
