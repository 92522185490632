export const FREE_COURSES = {
  collegeSuccess: 'ckyp0xzra00003e5zdb9d8mta'
}

export const COURSE_NAMES_MAPPING = {
  ckzmyc33d00003e5z5ju9w077: 'Introduction to Astronomy'
}

export const COURSE_ACCESS_TYPES = [
  {
    name: 'UPitt Intensive',
    duration: [7, 8],
    creditGrantingInstitution: 'University of Pittsburgh'
  },
  {
    name: 'UPitt Standard',
    duration: [14, 15],
    creditGrantingInstitution: 'University of Pittsburgh'
  },
  {
    name: 'UPitt Extended',
    duration: [39],
    creditGrantingInstitution: 'University of Pittsburgh'
  },
  {
    name: 'GGU Degrees+ Standard',
    duration: [14, 15],
    creditGrantingInstitution: 'Golden Gate University'
  },
  {
    name: 'GGU Professional Certificates',
    creditGrantingInstitution: 'Golden Gate University',
    profCert: true,
    duration: [14, 15]
  }
]
