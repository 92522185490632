import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const StyledFooter = styled.footer`
  background-color: var(--canvas-dark);
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .position-relative {
    z-index: 9999;
    ${mediaqueries.small`
      z-index: 1;
    `}
  }
  
  ${mediaqueries.medium`
      margin-bottom: 0px;
      background-color: var(--canvas-dark);
      bottom: 0px;
`}
`

export const ListGroup = styled.ul`
  display: inline-flex;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
`
export const Link = styled.a`
  color: var(--text-primary);
  padding: 12px 0px;
  display: inline-block;
  border: 1px solid var(--border-tertiary);
  font-size: calc(0.5rem + 0.375rem);
  border: 1px solid transparent;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  span {
    padding-left: 15px;
    padding-right: 15px;
    text-decoration: underline;
  }
  &:disabled {
    color: var(--brand-regular-disabled);
  }
  &:not(:disabled) {
    &:active {
      color: var(--brand-regular-active);
    }
    &:hover {
      color: var(--brand-regular);
    }
  }
`
