import React from 'react'
import Header from '../Layout/Header/Header'
import { StyledWrapper, StyledContent, StyledSpan } from './NoAccess.styled'

const NoAccess = () => {
  return (
    <StyledWrapper>
      <Header />
      <StyledContent>
        <StyledSpan>
          FORBIDDEN
          <br /><br />
          You don't have permission to access this site.
        </StyledSpan>
      </StyledContent>
    </StyledWrapper>
  )
}

export default NoAccess
