export const isConsultant = (email = '') => {
  return email?.endsWith('@consultant.outlier.org')
}

export const isOutlierAccount = (email) => {
  if (!email) return false

  return email.endsWith('@outlier.org') ||
      email.endsWith('@engineering.outlier.org') || isConsultant(email)
}
