import { useState } from 'react'
import { useAuth0 } from '../Auth0Provider/Auth0Provider'
import NoAccess from '../NoAccess/NoAccess'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { SpinnerWrap } from '../LoadingSpinner/LoadingSpinner.styled'

const AppGuard = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0()
  const [loader, setLoader] = useState(false)
  const forceLoginRedirect = () => {
    setLoader(true)
    setTimeout(() => {
      loginWithRedirect({
        redirect_uri: window.location.origin,
        appState: { targetUrl: window.location.href }
      })
      setLoader(false)
    }, 1000)
    return true
  }

  if (loader) return <SpinnerWrap><LoadingSpinner /></SpinnerWrap>
  if (!isAuthenticated) return forceLoginRedirect()
  if (!user.active) return <NoAccess />

  return children
}

export default AppGuard
