import styled from 'styled-components'
import { StyledFlex } from '../../components/global.styled'

export const StyledContainer = styled.div`
  margin: 64px auto;
  max-width: 1072px;
  @media (max-width: 1072px) {
    padding: 0 16px;
  }
  > div {
    margin-right: -8px;
    margin-left: -8px;
  }
`

export const StyledHeader = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 0;
`

export const StyledCardContainer = styled.div`
  padding-right: 8px;
  padding-left: 8px;
`

export const StyledCard = styled.div`
  background-color: ${({ disabled }) => disabled ? 'transparent' : 'var(--gray-primary)'};
  border: ${({ disabled }) => disabled ? '1px solid var(--border-secondary)' : '0 none'};
  padding: 32px 24px;
  border-radius: 5px;
  margin-bottom: 16px;
  height: 152px;
  cursor: ${({ disabled }) => disabled ? 'cursor' : 'pointer'};
  &:hover {
    text-decoration: none !important;
  }
`
export const StyledCardTitle = styled(StyledFlex)`
  align-items: center;
  font-size: 18px;
  vertical-align: middle;
  color: var(--text-primary);
  margin-left: 16px;
  img {
    margin-left: 4px;
  }
  &:hover{
    text-decoration: none !important;
  }
`
export const StyledCardDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #B1BFC5;
  margin-top: 16px;
  &:hover {
    text-decoration: none !important;
  }
`

export const StyledNavigateIcon = styled.img`
  margin-left: 12.5px !important;
`
