export const filterUnique = (array) => array.filter((item, index) => array.indexOf(item) === index)

// https://stackoverflow.com/a/46181/8443899
export const isValidEmail = email => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

export const isValidURL = url => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url)
}

export const filterValuesFromObject = (obj, callback) => {
  const filteredObj = {}

  Object.keys(obj).forEach(key => {
    if (callback(key, obj[key])) filteredObj[key] = obj[key]
  })

  return filteredObj
}

export const getTextWidth = text => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const metrics = context.measureText(text)
  return metrics?.width
}

export const getPlural = (word, count) => {
  if (count === 1) return word

  const plurals = {
    '(quiz)$': '$1zes',
    '([^aeiouy]|qu)y$': '$1ies',
    '([^s]+)$': '$1s'
  }

  for (const reg in plurals) {
    const pattern = new RegExp(reg, 'i')
    if (pattern.test(word)) {
      return word.replace(pattern, plurals[reg])
    }
  }
}

export const customMerge = (string1, string2) => {
  if (typeof string1 !== 'string' || typeof string2 !== 'string') return
  if (string1 === string2 || (string1.includes('|') && string2.includes('|'))) {
    return string1
  }

  return string1 + ', ' + string2
}

export const wait = (time = 0) =>
  new Promise(resolve => {
    setTimeout(resolve, time)
  })

export function capitalizeFirstLetter (string) {
  if (!string) return
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getNameWithoutVersion (name = '') {
  return name?.replace(/ *V\d */, ' ').trim()
}
