import React, { memo } from 'react'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import { Content } from './MainLayout.styled'

const MainLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className='layout-wrapper'>
        <Content id='main-content'>
          {children}
        </Content>
      </div>
      <Footer />
    </div>
  )
}

export default memo(MainLayout)
