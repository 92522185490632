import axios from 'axios'
import axiosRetry from 'axios-retry'
import createClient from './components/Auth0Provider/client'
import qs from 'qs'
import config from './config'
import { PRODUCTION_API_URL } from './constants'
import {
  filterCurrentPartnerCourses,
  filterPartnerCourses
} from './utilities/course'
import { addCohortStatusAndFormatCohorts } from './utilities/cohort'
import { COURSE_NAMES_MAPPING } from './constants/course'
import { NO_ASSIGNMENT } from './constants/writingGradeCenter'

axiosRetry(axios, {
  retries: 1,
  retryDelay: (retryCount) => retryCount * 1000,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    const is404Response = error?.response?.status === 404

    return !is404Response
  },
  onRetry: (retryCount, error, requestConfig) => {
    const { status } = error?.response || {}
    const { url } = requestConfig || {}

    console.error(
      'ON Error retry: ', JSON.stringify({ retryCount, statusCode: status, url })
    )
  }
})

const defaultApiHostUrl = config.getApiHost()

const cache = {}

const getToken = async () => {
  const client = await createClient()
  const token = await client.getIdToken()
  return token
}

const getApi = async (url, options) => {
  const {
    pageCache,
    pageCacheCallback = () => true,
    queryParams,
    apiHostUrl = defaultApiHostUrl
  } = options || {}

  const params = queryParams ? `?${qs.stringify(queryParams)}` : ''
  const fullUrl = `${apiHostUrl}/${url}${params}`

  if (pageCache && cache[fullUrl]) return cache[fullUrl]

  const token = await getToken()
  console.info(`API-get start ${fullUrl}`)
  try {
    const { data } = await axios.get(fullUrl, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API-get done ${fullUrl}`)

    if (pageCache && pageCacheCallback(data)) {
      cache[url] = { data, error: null }
      return cache[url]
    }

    return {
      data,
      error: null
    }
  } catch (error) {
    console.error(`API-get error ${fullUrl}`)
    return {
      error,
      data: null
    }
  }
}

const postApi = async (url, body, options) => {
  const token = await getToken()
  const fullUrl = `${defaultApiHostUrl}/${url}`

  console.info(`API-post start ${fullUrl}, body: `, body)
  try {
    const { data } = await axios.post(
      fullUrl,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(options || {})
        }
      }
    )
    console.info(`API-post done ${fullUrl}`)

    return {
      data,
      error: null
    }
  } catch (error) {
    console.error(`API-post error ${fullUrl}`)
    return {
      error,
      data: null
    }
  }
}

const putApi = async (url, body, options) => {
  const token = await getToken()
  const fullUrl = `${defaultApiHostUrl}/${url}`

  console.info(`API-put start ${fullUrl}, body: `, body)
  try {
    const { data } = await axios.put(fullUrl, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(options || {})
      }
    })
    console.info(`API-put done ${fullUrl}`)

    return {
      data,
      error: null
    }
  } catch (error) {
    console.error(`API-put error ${fullUrl}`)
    return {
      error,
      data: null
    }
  }
}

const deleteApi = async (url) => {
  const token = await getToken()
  const fullUrl = `${defaultApiHostUrl}/${url}`
  console.info(`API-delete start ${fullUrl}`)
  try {
    const { data } = await axios.delete(fullUrl, {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.info(`API-delete done ${fullUrl}`)
    return {
      data,
      error: null
    }
  } catch (error) {
    console.error(`API-delete error ${fullUrl}`)
    return {
      error
    }
  }
}

const logError = (data) => {
  return postApi('monitor/log-frontend-error', data)
}

const timeoutPromise = (promise, ms) => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request timed out'))
    }, ms)

    promise.then(
      (res) => {
        clearTimeout(timeoutId)
        resolve(res)
      },
      (err) => {
        clearTimeout(timeoutId)
        reject(err)
      }
    )
  })
}

const getAccessPermissions = async () => {
  return getApi('partners/access/permissions')
}

const getPartnerId = async () => {
  return getApi('student/student-id')
}

const getStudentStatuses = async (queryParams) => {
  return getApi('partners/student-statuses', { queryParams })
}

export const getCourses = async () => {
  const coursesResponse = await getApi('courses', { pageCache: true })

  if (coursesResponse.error) return coursesResponse

  const formattedCourses = coursesResponse.data.map(course => {
    const { id, name } = course
    return {
      ...course,
      name: COURSE_NAMES_MAPPING[id] || name
    }
  })

  return {
    data: formattedCourses,
    error: null
  }
}

export const getProspectsData = async studentEmail => {
  return getApi(
    `prospects/prospect-data?studentEmail=${encodeURIComponent(studentEmail)}`,
    { pageCache: true }
  )
}

export const getAvailableTokens = async (relationshipId = null, body) => {
  const url = relationshipId
    ? `partners/available-tokens?relationshipId=${relationshipId}`
    : 'partners/available-tokens'
  return postApi(url, body)
}

export const enrollStudents = async (body) => {
  const token = await getToken()
  const fullUrl = `${defaultApiHostUrl}/partners/enroll-partner-students`

  console.info(`API-post start ${fullUrl}, body: `, body)

  try {
    const promise = axios.post(
      fullUrl,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    const { data } = await timeoutPromise(promise, 15000)

    console.info(`API-post done ${fullUrl}`)

    return {
      data,
      error: null
    }
  } catch (error) {
    console.error(`API-post error ${fullUrl}`)

    if (error?.message === 'Request timed out') {
      return {
        awaitingResponse: true
      }
    }

    return {
      error,
      data: null
    }
  }
}

export const getActiveEnrollmentCohorts = async (relationshipId) => {
  const response = await getApi(`partners/active-cohorts/${relationshipId}`, { pageCache: true })
  return response
}

export const getPartnerCohorts = async (isMulti) => {
  const url = isMulti ? 'partners/cohorts?allRelationships=true' : 'partners/cohorts'
  const partnerCohortsResponse = await getApi(url, { pageCache: true })

  if (partnerCohortsResponse.error) return partnerCohortsResponse

  const { cohorts } = partnerCohortsResponse.data

  return {
    ...partnerCohortsResponse,
    data: {
      ...partnerCohortsResponse.data,
      cohorts: addCohortStatusAndFormatCohorts(cohorts)
    }
  }
}

export const bulkStatusModification = async (cohortId, body) => {
  return putApi(`partners/bulk-status-modification/${cohortId}`, body)
}

export const getCohortStudents = cohortId => {
  return getApi(`students/${cohortId}`)
}

export const getPartnerCohortStudents = cohortId => {
  return getApi(`partners/students/${cohortId}`)
}

export const getCatalogCourses = async () => {
  const catalogResponse = await getApi(
    'dato/catalog',
    {
      pageCache: true,
      pageCacheCallback: (data) => {
        if (!data) return false

        return !!Object.keys(data).length
      }
    }
  )

  // If response status is unauthorized and api host url is not production or
  // If response data is empty and api host url is not production,fetch it again
  // with production api url.
  const shouldTryWithProductionApi = defaultApiHostUrl !== PRODUCTION_API_URL &&
    (catalogResponse?.error?.response?.status === 401 ||
      Object.keys(catalogResponse?.data).length === 0)
  if (shouldTryWithProductionApi) {
    return getApi(
      'dato/catalog',
      { pageCache: true, apiHostUrl: PRODUCTION_API_URL }
    )
  }

  return catalogResponse
}

export const getCourseData = courseId => {
  if (!courseId) return
  return getApi(`dato/files/${courseId}/${courseId}`,
    {
      pageCache: true,
      apiHostUrl: PRODUCTION_API_URL
    }
  )
}

export const getSectionData = (courseId, sectionId) => {
  return getApi(`dato/files/${courseId}/${sectionId}`, { pageCache: true })
}

export const getMultipleSectionData = async (courseId, sectionIds) => {
  const response = await Promise.all(
    sectionIds.map(sectionId => getSectionData(courseId, sectionId))
  )

  return {
    data: response
      ?.filter((data) => !data.error)
      ?.map(({ data }) => data)
  }
}

export const getCourseraGrades = ({ courseId, cohortId, studentEmail }) => {
  return getApi(
    `student/coursera-progress/${courseId}/${cohortId}/${studentEmail}`,
    { pageCache: true }
  )
}

export const getStudentProgress = ({
  courseId,
  cohortId,
  isCurrentCohort,
  studentEmail
}) => {
  const url = isCurrentCohort
    ? `student/progress/${courseId}/${studentEmail}`
    : `student/progress/${courseId}/${cohortId}/${studentEmail}`

  return getApi(url)
}

export const updateStudentProgress = async ({
  key, courseId, payload
}) => {
  const url = `student/progress/multiple-students/${key}/${courseId}`
  return putApi(url, payload)
}

export const getCohortSyllabus = cohortId => {
  return getApi(`cohort-syllabus/${cohortId}`, { pageCache: true })
}

export const getPartnerCourses = async () => {
  const [allCourses, catalogCourses] = await Promise.all([
    getCourses(),
    getCatalogCourses()
  ])

  if (allCourses.error || catalogCourses.error) {
    return { data: [], error: null }
  }

  return {
    data: filterPartnerCourses(allCourses.data, catalogCourses.data),
    error: null
  }
}

export const getCurrentPartnerCourses = async () => {
  const [allCourses, currentRelationship] = await Promise.all([
    getCourses(),
    getPartnerRelationships()
  ])

  if (allCourses.error || currentRelationship.error) {
    return { data: [], error: null }
  }

  const { data: { fields: { relationshipName } } } = currentRelationship || {}

  return {
    data: filterCurrentPartnerCourses(allCourses.data, relationshipName),
    error: null
  }
}

export const getStudentsGradeData = (courseId, queryParams, cache = false) => {
  const queryString = qs.stringify(queryParams)
  return getApi(
    `student/grades/sections/${courseId}?${queryString}`, { pageCache: cache })
}

export const getStudentsCurrentGrade = (courseId, cohortName, queryParams = {}) => {
  const queryString = qs.stringify(queryParams)
  return getApi(
    `student/current-grade/${courseId}/${encodeURIComponent(cohortName)}?${queryString}`
  )
}

export const updateAuth0User = async body => {
  return putApi('update/auth0/user', body)
}

export const sendWelcomeEmail = async body => {
  return postApi('welcome-email', body)
}

export const getGradesCSV = async body => {
  return postApi('partners/grades-export', body)
}

export const sendErrorEnrollmentEmail = async formData => {
  return postApi(
    'partners/error-enrollment-email',
    formData,
    { 'Content-type': 'multipart/form-data' }
  )
}

export const getPartnerRelationships = async (isMulti) => {
  const url = isMulti ? 'partners/relationship?multiple=true' : 'partners/relationship'
  return getApi(url, { pageCache: true })
}

export const sendInvoiceRequestEmail = async body => {
  return putApi('partners/request-invoice-email', body)
}

async function getStudentSubmissions (courseId, queryParams) {
  return getApi(
    `partners/student-grade/${courseId}/assignments?${queryParams}`,
    { pageCache: true }
  )
}

async function getAllCohorts (queryParams) {
  return getApi(`cohorts?${qs.stringify(queryParams)}`, { pageCache: true })
}

async function getCohortMilestones (cohortId) {
  return getApi(`partners/cohort-milestones/${cohortId}`, { pageCache: true })
}

async function getWritingAssignment ({
  courseId,
  cohortID,
  assignmentUUID,
  studentEmail,
  fileName,
  multiPartAssignment
}) {
  const token = await getToken()

  try {
    const queryParams = `studentEmail=${studentEmail}${multiPartAssignment ? '&multiPartAssignment=true' : ''}`
    const url = `${defaultApiHostUrl}/student/writing-assignment/${courseId}/${cohortID}/${assignmentUUID}/${fileName || ''}?${queryParams}`
    const response = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'arraybuffer'
      })
    const { data } = response
    if (data && data.error) return { error: data.error }
    return response
  } catch (e) {
    console.error('Error when getting writing assignment', e.message)
    return { error: NO_ASSIGNMENT }
  }
}

async function getStudentsByCohort (cohortId) {
  return getApi(`students/${cohortId}`, { pageCache: true })
}

async function submitTrackedEvent (rawData) {
  console.info('API start submitTrackedEvent')
  const allowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const params = qs.parse(window.location.search.slice(1))
  const utmPropertiesArray = Object.keys(params)
    .filter(key => allowedKeys.includes(key))
    .map(key => ([key, params[key]]))
  const utmProperties = Object.fromEntries(utmPropertiesArray)

  const properties = { ...rawData.properties, ...utmProperties }
  const eventData = { ...rawData, properties }

  return postApi('partners/analytics/track-event', eventData)
}

async function setStudentProgress ({ key, courseId }, studentEmail, sectionData) {
  return putApi(
    `partners/student-exam-grade/${key}/${encodeURIComponent(studentEmail)}/${courseId}`,
    sectionData
  )
}

async function putKamiDocument (
  formData, { studentEmail, courseId, cohortId, assignmentUUID }
) {
  return postApi(
    `partners/kami/upload/document/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`,
    formData, { 'Content-type': 'multipart/form-data' }
  )
}

async function getKamiDocument (
  { studentEmail, courseId, cohortId, assignmentUUID, documentIdentifier }
) {
  return getApi(
    `partners/kami/document/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}/${documentIdentifier}`,
    { pageCache: true }
  )
}

async function createKamiViewSession (studentEmail, body) {
  return postApi(`partners/kami/create/view-session/${studentEmail}`, body)
}

async function putAssignmentFeedbackFile ({
  studentEmail,
  courseId,
  cohortId,
  assignmentUUID,
  formData
}) {
  return putApi(
    `partners/assignment-feedback-file/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`,
    formData, { 'Content-type': 'multipart/form-data' }
  )
}

async function deleteAssignmentFeedbackFile ({
  studentEmail,
  courseId,
  cohortId,
  assignmentUUID
}) {
  return deleteApi(
    `partners/assignment-feedback-file/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`
  )
}

async function getAssignmentFeedbackFile ({ courseId, cohortId, assignmentUUID, studentEmail }) {
  const token = await getToken()
  const url = `${defaultApiHostUrl}/student/assignment-feedback-file/${courseId}/${cohortId}/${assignmentUUID}?studentEmail=${
    studentEmail}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob'
    })

    return data
  } catch (e) {
    console.error(`API error in getAssignmentFeedbackFile: ${e.message}`)
    return { error: e.message }
  }
}

async function getStudentData (queryParams) {
  return getApi(
    `student/student-data?${queryParams}`,
    { pageCache: true }
  )
}

async function putAssignmentModifications (
  studentEmail,
  { courseId, cohortId },
  editData
) {
  const url = cohortId
    ? `partners/assignment-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `partners/assignment-grade-modification/${studentEmail}/${courseId}`
  return putApi(url, editData)
}

async function putStudentExamModifications (
  studentEmail,
  { courseId, cohortId },
  editData
) {
  const url = cohortId
    ? `partners/exam-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `partners/exam-grade-modification/${studentEmail}/${courseId}`
  return putApi(url, editData)
}

async function putStudentSectionModification (
  studentEmail,
  { courseId, cohortId, progressKey },
  editData) {
  const url = cohortId
    ? `partners/modify-section-grade/${studentEmail}/${progressKey}/${courseId}/${cohortId}`
    : `partners/modify-section-grade/${studentEmail}/${progressKey}/${courseId}`
  return putApi(url, editData)
}

async function getParticipationModifications (studentEmail, courseId) {
  return getApi(`partners/participation-grade-modification/${studentEmail}/${courseId}/participation`)
}

async function putParticipationModifications (
  studentEmail,
  { courseId, cohortId },
  editData
) {
  const url = cohortId
    ? `partners/participation-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `partners/participation-grade-modification/${studentEmail}/${courseId}`
  return putApi(url, editData)
}

async function getAssignmentModifications (courseId, studentEmail) {
  return getApi(`partners/assignment-grade-modification/${studentEmail}/${courseId}`)
}

async function getStudentSectionModifications (courseId, studentEmail) {
  return getApi(`partners/modify-section-grade/${studentEmail}/${courseId}`)
}

async function getExamGradeModifications (courseId, studentEmail) {
  return getApi(`partners/exam-grade-modification/${studentEmail}/${courseId}`)
}

async function getStudentCourses () {
  return getApi('student/courses', { pageCache: true })
}

async function getStudentCoursesByEmail (studentEmail) {
  return getApi(`partners/courses/${encodeURIComponent(studentEmail)}`)
}

async function getExamExtensionsTable ({
  offset,
  cohortFilter = '',
  studentFilter = '',
  courseFilter = '',
  isGGU = false
}) {
  let url = `partners/ggu-exam-extensions?limit=10&offset=${offset}&sort=updatedAt`
  url += cohortFilter !== '' ? `&cohortId=${cohortFilter}` : ''
  url += studentFilter !== '' ? `&search=${encodeURIComponent(studentFilter)}` : ''
  url += courseFilter !== '' ? `&courseId=${courseFilter}` : ''
  url += `&isGGU=${isGGU}`

  return getApi(url)
}

async function getStudentExtensionRecords (studentIds) {
  return postApi('partners/assessment-extensions', { studentIds })
}

async function getStudentDataByEmailOrId (
  studentIdOrEmail,
  isGGU = false
) {
  const url = `partners/student-data/${studentIdOrEmail}?isGGU=${isGGU}`
  return getApi(url)
}

async function addAssessmentExtensions (extensionData) {
  return postApi('partners/create/assessment-extensions', extensionData)
}

async function getCohortById (cohortId) {
  return getApi(`partners/cohorts/details/${cohortId}`)
}

async function putAssessmentExtension (extensionData) {
  return putApi('partners/update/assessment-extensions', extensionData)
}

async function getStudentsByExam (courseId, cohortId, examId, relationshipId) {
  const url = `partners/students/exam-status/${courseId}/${cohortId}/${examId}`
  if (relationshipId) {
    return getApi(`${url}/${relationshipId}`)
  }
  return getApi(url)
}

async function getMaxParticipationGrade (courseId) {
  return getApi(`course/participation-max/${courseId}`)
}

async function checkStudentsEligibility (body) {
  return postApi('partners/check-student-eligibility', body)
}

const api = {
  updateStudentProgress,
  getProspectsData,
  getToken,
  getStudentsCurrentGrade,
  getPartnerId,
  getCohortSyllabus,
  getGradesCSV,
  getCourseraGrades,
  getStudentProgress,
  getCourseData,
  getSectionData,
  getStudentsGradeData,
  getStudentStatuses,
  getCourses,
  getCohortStudents,
  getPartnerCohortStudents,
  updateAuth0User,
  sendWelcomeEmail,
  getPartnerCourses,
  getAvailableTokens,
  getAccessPermissions,
  getPartnerCohorts,
  getActiveEnrollmentCohorts,
  bulkStatusModification,
  getCurrentPartnerCourses,
  sendErrorEnrollmentEmail,
  getPartnerRelationships,
  sendInvoiceRequestEmail,
  enrollStudents,
  getStudentSubmissions,
  getAllCohorts,
  getCohortMilestones,
  getWritingAssignment,
  getStudentsByCohort,
  getStudentsByExam,
  submitTrackedEvent,
  setStudentProgress,
  putKamiDocument,
  getKamiDocument,
  createKamiViewSession,
  putAssignmentFeedbackFile,
  deleteAssignmentFeedbackFile,
  getAssignmentFeedbackFile,
  getStudentData,
  getAssignmentModifications,
  putAssignmentModifications,
  getParticipationModifications,
  putParticipationModifications,
  putStudentExamModifications,
  putStudentSectionModification,
  getStudentSectionModifications,
  getExamGradeModifications,
  getStudentCourses,
  getCatalogCourses,
  logError,
  getExamExtensionsTable,
  getStudentExtensionRecords,
  getStudentDataByEmailOrId,
  getStudentCoursesByEmail,
  addAssessmentExtensions,
  getCohortById,
  putAssessmentExtension,
  getMaxParticipationGrade,
  checkStudentsEligibility
}

export default api
