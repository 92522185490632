import { useEffect, useRef, useState, useCallback } from 'react'

const useSafeState = (defaultValue) => {
  const [state, setState] = useState(defaultValue)
  const isUnmountedRef = useRef(false)

  useEffect(() => () => (isUnmountedRef.current = true), [])

  const setter = useCallback(
    (val) => !isUnmountedRef.current && setState(val),
    []
  )

  return [state, setter]
}

export default useSafeState
