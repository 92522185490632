import { FINAL_EXAM, MIDTERM_EXAM_1, MIDTERM_EXAM_2 } from '.'

export const CHAPTER = 'chapter'
export const EXAM = 'exam'
export const ESSAY = 'essay'
export const SECTION = 'section'
export const WRITING_ASSIGNMENT = 'WritingAssignmentChapterRecord'
export const ORIENTATION = 'orientation'
export const REVIEW = 'review'
export const QUIZ = 'Quiz'
export const COURSERA_ACTIVITY = 'coursera activity'
export const BREAK = 'break'
export const TERM_BREAK = 'Term break'

export const EXAM_SHORT_CODE = {
  [MIDTERM_EXAM_1]: 'Mid 1',
  [MIDTERM_EXAM_2]: 'Mid 2',
  [FINAL_EXAM]: 'Final'
}
