export const TO_BE_ANNOUNCED = 'TBA'
export const MIDTERM_EXAM_1 = 'Midterm Exam 1'
export const MIDTERM_EXAM_2 = 'Midterm Exam 2'
export const FINAL_EXAM = 'Final Exam'
export const PRODUCTION_API_URL = 'https://api.outlier.org'
export const MAX_PARTICIPATION_GRADE = 5
export const MIN_PARTICIPATION_GRADE = 0
export const VERSION_B = 'Version B'
export const PARTNERSHIPS_MAIL_ADDRESS = 'partnerships@outlier.org'
export const TOKEN_PRICE = 597

export const GGU_RELATIONSHIP = 'GGU'

export const SPECIAL_MENU_RELATIONSHIPS = [
  GGU_RELATIONSHIP
]

export const HIDDEN_FEATURES = {
  [GGU_RELATIONSHIP]: ['Final Letter Grade']
}

export const FIRST_EDITION_SUBDOMAIN = 'firstedition'

export const DESMOS_SCRIPT_LINK = 'https://www.desmos.com/api/v1.5/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6'
export const MATHJAX_SCRIPT_LINK = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_SVG'

export const WIRIS_SCRIPT_LINK = 'https://www.wiris.net/demo/editor/editor'

export const JDOODLE_COMPILER_SCRIPT = 'https://outlier.jdoodle.com/assets/jdoodle-pym.min.js'

export const EXAM_DATE_KEYS = [
  {
    start: 'midTerm1StartTime',
    end: 'midTerm1EndTime',
    extension: 'midterm1DeadlineExt'
  },
  {
    start: 'midTerm2StartTime',
    end: 'midTerm2EndTime',
    extension: 'midterm2DeadlineExt'
  },
  {
    start: 'exam3StartTime',
    end: 'exam3EndTime',
    extension: 'exam3DeadlineExt'
  },
  {
    start: 'exam4StartTime',
    end: 'exam4EndTime',
    extension: 'exam4DeadlineExt'
  },
  {
    start: 'exam5StartTime',
    end: 'exam5EndTime',
    extension: 'exam5DeadlineExt'
  },
  {
    start: 'finalExamStartTime',
    end: 'finalExamEndTime',
    extension: 'finalDeadlineExt'
  }
]

export const MIDTERM_1_KEY = 'midterm1Key'
export const MIDTERM_2_KEY = 'midterm2Key'
export const EXAM_3_KEY = 'exam3Key'
export const EXAM_4_KEY = 'exam4Key'
export const EXAM_5_KEY = 'exam5Key'
export const FINAL_EXAM_KEY = 'finalExamKey'

export const EXAM_KEYS = [
  MIDTERM_1_KEY,
  MIDTERM_2_KEY,
  EXAM_3_KEY,
  EXAM_4_KEY,
  EXAM_5_KEY,
  FINAL_EXAM_KEY
]

export const EXAM_LOCK_STATUSES = {
  LOCKED: 'Locked',
  UNLOCKED: 'Unlocked'
}
