import React, { Component } from 'react'

import api from '../../api'
import { Wrapper } from './ErrorBoundary.styled'
import { serializeError } from 'serialize-error'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    const stack = errorInfo.componentStack
      .split(/\n\s+/)
      .filter(Boolean)

    api.logError({
      ...serializeError(error),
      url: ' - ' + window.location.href,
      fullStoryUrl: localStorage.getItem('OUTLIER_CURRENT_FS_SESSION') ?? 'test fullstory url',
      stack
    })
    console.error(error, errorInfo)
  }

  render () {
    const { hasError } = this.state
    const { children } = this.props

    if (!hasError) return children

    return (
      <Wrapper>
        <h1>Something went wrong.</h1>
      </Wrapper>
    )
  }
}

export default ErrorBoundary
